import { getCountryCart } from '@ecomm/checkout-commercetools/helpers/ct-cart-helper';
import useGetCartItems from '@ecomm/commercetools/hooks/useGetCartItems';
import { ShopCartUpdateActionType } from '@ecomm/graphql/types.generated';
import { useUpdateShopCartJupiterMutation } from '@ecomm/shop-cart/graphql/mutations/UpdateCart.generated';

export const useClearCartOnLogout = () => {
  const [updateCartMutation] = useUpdateShopCartJupiterMutation();
  const { data: cartData } = useGetCartItems();

  return async () => {
    if (!cartData?.id) return;

    await updateCartMutation({
      variables: {
        input: {
          country: getCountryCart(),
          actions: [
            {
              actionType: ShopCartUpdateActionType.SetCustomerEmail,
              setCustomerEmail: {
                customerEmail: '',
              },
            },
            {
              actionType: ShopCartUpdateActionType.SetShippingAddress,
              setShippingAddress: {
                address: null,
              },
            },
            {
              actionType: ShopCartUpdateActionType.SetBillingAddress,
              setBillingAddress: {
                address: null,
              },
            },
            ...(cartData?.payments ?? []).map(payment => ({
              actionType: ShopCartUpdateActionType.RemovePayment,
              removePayment: {
                type: payment.type,
                token: payment.paymentToken,
                id: payment.id,
              },
            })),
          ],
        },
      },
    });
  };
};
