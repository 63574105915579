import { brand, grey, spacing, white, Eyebrow, Flex } from '@pelotoncycle/design-system';
import StyledIcon from '@pelotoncycle/design-system/icons/AsyncIcon';
import React, { useContext } from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import { isBrowser } from '@peloton/browser/identify';
import { getCurrentRelativePathname } from '@peloton/internationalize/models/path';
import { defaultTransition, underlineActive } from '@peloton/styles';
import { Link } from '@ecomm/internationalize-ui';
import { NavMenuArrow as MenuArrowIcon } from '../Icons/NavMenuArrow';
import { NavContext } from '../NavProvider';
import { baseTopNavStyles, navHoverOpacity } from '../styles';
import type {
  DealsSubNavProps,
  ProductSubNavProps,
  ProductSubNavMenuItemProps,
  ProductSubNavDirectLinkItemProps,
} from '../types';
import { ProductSubNavType } from '../types';
import { isNavMenuOpen } from '../utils';
import ProductNavMenu from './ProductNavMenu';

export const ProductSubNavRow: FC<{
  dealsSubNav: DealsSubNavProps;
  productSubNavs: ProductSubNavProps[];
  setIsMenuOpen: (isMenuOpen: boolean) => void;
}> = ({ dealsSubNav, productSubNavs, setIsMenuOpen }) => {
  const { selectedNavItem } = useContext(NavContext);
  const activePath = isBrowser ? getCurrentRelativePathname()! : '/';

  React.useEffect(() => {
    setIsMenuOpen(selectedNavItem !== null);
  }, [selectedNavItem, setIsMenuOpen]);

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      gap={spacing[40]}
      style={{
        height: '100%',
        flexGrow: 1,
        flexShrink: 0,
        flexBasis: 0,
      }}
      data-test-id="productsMenuList"
    >
      {dealsSubNav?.promo?.showDealsInNav && (
        <StyledSubNavContainer>
          <ProductSubNavDirectLinkItem
            title={dealsSubNav.subNavComponent.text}
            directLink={dealsSubNav.subNavComponent.link}
            textColor={brand.accent}
            isActive={
              activePath !== '/'
                ? dealsSubNav.subNavComponent.link.url.includes(activePath)
                : false
            }
          />
        </StyledSubNavContainer>
      )}
      {productSubNavs?.map(productSubNav => {
        const navMenuOpen = isNavMenuOpen(selectedNavItem, productSubNav.title);
        const isActiveMenu = isActiveMenuItem(productSubNav, activePath);
        return (
          <StyledSubNavContainer key={productSubNav?.title}>
            {productSubNav.typeOfProductSubNav.type === ProductSubNavType.MenuDropDown ? (
              <>
                <ProductSubNavMenuItem
                  key={productSubNav?.title}
                  title={productSubNav?.title}
                  isMenuOpen={navMenuOpen}
                  isActive={isActiveMenu && !navMenuOpen}
                />
                {navMenuOpen && <ProductNavMenu {...productSubNav} />}
              </>
            ) : (
              <ProductSubNavDirectLinkItem
                title={productSubNav?.title}
                directLink={productSubNav.typeOfProductSubNav?.directLink!}
                textColor={white}
                isActive={isActiveMenu}
              />
            )}
          </StyledSubNavContainer>
        );
      })}
    </Flex>
  );
};

const ProductSubNavMenuItem: FC<ProductSubNavMenuItemProps> = ({
  title,
  isMenuOpen = false,
  isActive = false,
}) => {
  const { setSelectedNavItem } = useContext(NavContext);

  const clickHandler = () => {
    const navItem = isMenuOpen ? null : title;
    setSelectedNavItem(navItem);
  };

  return (
    <StyledNavButton onClick={clickHandler}>
      <StyledNavButtonText>
        <ProductSubNavText title={title} textColor={white} isActive={isActive} />
        <StyledIcon
          name="chevron"
          height={8}
          primaryColor={grey[60]}
          rotate={isMenuOpen ? 90 : -90}
        />
      </StyledNavButtonText>
      <MenuArrowIcon isMenuOpen={isMenuOpen} />
    </StyledNavButton>
  );
};

const ProductSubNavDirectLinkItem: FC<ProductSubNavDirectLinkItemProps> = ({
  title,
  directLink,
  textColor,
  isActive = false,
}) => {
  return (
    <StyledNavLink to={directLink?.url} href={directLink?.url}>
      <StyledNavButtonText>
        <ProductSubNavText title={title} textColor={textColor} isActive={isActive} />
      </StyledNavButtonText>
    </StyledNavLink>
  );
};

const ProductSubNavText: FC<{ title: string; textColor: string; isActive: boolean }> = ({
  title,
  textColor,
  isActive,
}) => {
  return (
    <StyledEyebrow size={'medium'} textColor={textColor} isActive={isActive}>
      {title}
    </StyledEyebrow>
  );
};

const isActiveMenuItem = (subNav: ProductSubNavProps, activePath: string) => {
  if (activePath === '/') {
    return false;
  }

  return !!(
    subNav.productDetailCards?.some(card => {
      return (
        card.primaryCTA.link.url.includes(activePath) ||
        card.secondaryCTA?.link.url.includes(activePath)
      );
    }) || subNav.typeOfProductSubNav?.directLink?.url.includes(activePath)
  );
};

export default ProductSubNavRow;

const StyledSubNavContainer = styled(Flex)`
  position: relative;
  height: 100%;
`;

const baseProductSubNavStyles = `
  ${baseTopNavStyles}
  ${defaultTransition()}
`;

const StyledNavButton = styled.button`
  ${baseProductSubNavStyles}
`;

const StyledNavLink = styled(Link)`
  ${baseProductSubNavStyles}
`;

const StyledNavButtonText = styled(Flex)`
  align-items: center;
  gap: ${spacing[8]};
  ${navHoverOpacity}
`;

const StyledEyebrow = styled(Eyebrow)<{ isActive: boolean }>`
  ${props => (props.isActive ? `${underlineActive}` : null)}
`;
