import { Eyebrow } from '@pelotoncycle/design-system';
import React from 'react';
import type { FC } from 'react';
import { useClearCartOnLogout } from '@ecomm/commercetools/hooks/useClearCartOnLogout';
import { LinkButton } from '@ecomm/internationalize-ui';
import useLogout from '@ecomm/oauth/useLogout';

type SignOutLinkProps = {
  text: string;
  currentHref: string;
};
export const SignOutLink: FC<SignOutLinkProps> = ({ text, currentHref }) => {
  const logout = useLogout();
  const clearCart = useClearCartOnLogout();

  const clickHandler = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    clearCart();
    await logout({}, { logoutParams: { returnTo: currentHref } });
  };

  return (
    <LinkButton to={'#'} href={'#'} hasUnderline={false} onClick={clickHandler}>
      <Eyebrow size="medium">{text}</Eyebrow>
    </LinkButton>
  );
};
