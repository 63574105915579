import { grey, spacing, Flex } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import type { Money } from '@ecomm/models';
import ItemDescriptionView from './ItemDescriptionView';
import ItemPriceView from './ItemPriceView';
import ItemsInBundleView from './ItemsInBundleView';
import ItemTitleView from './ItemTitleView';
import QuantityView from './QuantityView';
import RemoveItem from './RemoveItem';

type Props = {
  className?: string;
  name: string;
  itemQty: number;
  itemPrice: Money;
  bundleItems: any[];
  kind?: string;
  description: string;
  optionId: string;
};

const RemovableCartItemView: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  name,
  itemQty,
  itemPrice,
  bundleItems,
  kind,
  description,
  optionId,
}) => {
  const isMembership = kind === 'MembershipItem';
  const isPrepaidCredits = kind === 'PrepaidMembershipCreditsItem';
  const isMembershipType = isMembership || isPrepaidCredits;
  const isLeasingBundle = kind === 'LeasingBundleItem';

  return (
    <Item className={className} isMembership={isMembership}>
      <Flex justifyContent="space-between" alignItems="flex-start">
        <ItemTitleView name={name} isMembership={isMembershipType} />
        <QuantityView quantity={itemQty} />
      </Flex>
      <Row justifyContent="space-between" alignItems="flex-start">
        <Flex flexDirection="column">
          <ItemsInBundleView bundleItems={bundleItems} />
          <StyledItemDescriptionView
            description={description}
            showDescription={isMembershipType}
            isPrepaidCredits={isPrepaidCredits}
          />
          <ItemPriceView
            priceInCents={itemPrice}
            isMembership={isMembership}
            isLeasingBundle={isLeasingBundle}
          />
        </Flex>
        {!isMembership && <RemoveItem optionId={optionId} />}
      </Row>
    </Item>
  );
};

const Item = styled.div<{ isMembership?: boolean }>`
  ${props =>
    props.isMembership
      ? `border-top: none`
      : `border-top: 1px solid ${grey[50]}; padding-top: ${spacing[16]};
    `}
`;

const Row = styled(Flex)`
  margin-bottom: ${spacing[24]};
`;

const StyledItemDescriptionView = styled(ItemDescriptionView)<{
  isPrepaidCredits?: boolean;
}>`
  ${props => props.isPrepaidCredits && `margin-bottom: ${spacing[8]}`}
`;

export default RemovableCartItemView;
