import { white, spacing, grey } from '@pelotoncycle/design-system';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import type { SecondaryLinksData } from '@ecomm/header/models';
import { Link } from '@ecomm/internationalize-ui';
import useFilteredSecondaryLinks from '../hooks/useFilteredSecondaryLinks';
import { useIsUserInUnitedStates } from '../hooks/useIsUserInUnitedStates';

type Props = SecondaryLinksData & {
  handleClose: () => void;
  className?: string;
};

const SecondaryLinks: React.FC<React.PropsWithChildren<Props>> = ({
  categoryId,
  secondaryLinks,
  handleClose,
  className = '',
}) => {
  const { trackEvent } = useTracking();
  const filteredLinks = useFilteredSecondaryLinks(secondaryLinks);
  const isUSLocale = useIsUserInUnitedStates();

  const isDesktopHamburgerNavActive = isUSLocale;

  return filteredLinks ? (
    <SecondaryLinksContainer
      data-test-id={secondaryLinks.key}
      useDesktopHamburgerStyles={isDesktopHamburgerNavActive}
    >
      {filteredLinks.map(({ key, text, url }) => (
        <StyledListElement key={text} className={className}>
          <SecondaryLink
            data-test-id={key}
            useDesktopHamburgerStyles={isDesktopHamburgerNavActive}
            to={url}
            href={url}
            children={text}
            size="large"
            variant="body"
            hasUnderline={false}
            onClick={() => {
              trackEvent({
                properties: {
                  category: categoryId,
                  parent: secondaryLinks.key,
                  unitName: key,
                  linkTo: url,
                  linkName: text,
                },
              });
              !isDesktopHamburgerNavActive && handleClose();
            }}
          />
        </StyledListElement>
      ))}
    </SecondaryLinksContainer>
  ) : null;
};

export default SecondaryLinks;

const SecondaryLinksContainer = styled.ul<{ useDesktopHamburgerStyles: boolean }>`
  padding: 0.5rem 0;

  li {
    display: block;
    margin: ${({ useDesktopHamburgerStyles }) =>
      useDesktopHamburgerStyles ? `${spacing[16]} 0` : `${spacing[32]} 0`};
    margin: ${spacing[16]} 0;
    &.nav-panel-item {
      opacity: 0;
    }
  }
`;

const StyledListElement = styled('li')`
  opacity: 1 !important;
`;

const SecondaryLink = styled(Link)<{ useDesktopHamburgerStyles: boolean }>`
  color: ${({ useDesktopHamburgerStyles }) =>
    useDesktopHamburgerStyles ? `${grey[30]}` : `${white}`};

  &:hover {
    ${({ useDesktopHamburgerStyles }) =>
      useDesktopHamburgerStyles ? `color: ${grey[50]};` : ''};
  }

  span {
    font-size: ${spacing[16]};
  }
`;
