import type { Locale } from '@peloton/internationalize';
import type { ProductProjection } from '@ecomm/commercetools/types.generated';
import type { CommercetoolsPrepaidSubscription } from '@ecomm/shop-configuration';
import { filterVariantsByAvailability } from '../product-states/getSalesStatus';
import { mapProductAttributesToConfigs } from './mapProductAttributesToConfigs';

export const mapProductToPrepaidSubscription = (
  product: ProductProjection,
  locale: Locale,
): CommercetoolsPrepaidSubscription => {
  const { masterVariant, variants = [] } = product;
  const filteredVariants =
    variants.length > 0
      ? filterVariantsByAvailability([masterVariant, ...variants], locale)
      : [masterVariant];

  const configurations = mapProductAttributesToConfigs(
    masterVariant,
    filteredVariants,
    locale,
    true,
  );

  return {
    variants: configurations,
  };
};
