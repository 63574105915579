import { red, Eyebrow } from '@pelotoncycle/design-system';
import styled from 'styled-components';
import { media } from '@peloton/styles';

export const ItemCountBadge = styled(Eyebrow)`
  display: flex;
  background: ${red[80]};
  height: 17px;
  width: 17px;
  border-radius: 50%;
  place-content: center;
  align-items: center;
  position: absolute;
  left: 20px;
  top: -3px;

  ${media.tabletXLarge`
    width: 20px;
    height: 20px;
  `}
`;
