import {
  Checkbox,
  Flex,
  grey,
  spacing,
  Icon,
  Support,
  black,
  white,
} from '@pelotoncycle/design-system';
import React, { useState } from 'react';
import styled from 'styled-components';
import {
  ELIGIBLE_LOCALES_FOR_GIFTING_ENHANCEMENTS,
  useLocale,
} from '@peloton/internationalize';
import useCart from '@content/client/www/cart/useCart';
import { isCartEligibleForGifting } from '@ecomm/checkout-commercetools/helpers/ct-cart-helper';
import useGetCartItems from '@ecomm/commercetools/hooks/useGetCartItems';
import useTrackGiftCheckbox from '@ecomm/pg-checkout-commercetools/analytics/useCTGiftCheckbox';
import { debounce } from '@ecomm/pg-checkout-commercetools/utils/debounce';
import useSetIsGiftOnCart from '../../hooks/useSetIsGiftOnCart';
import GiftingMembership from './GiftingMembership';

const Gifting: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { content: cartContent } = useCart();
  const { data: shopCartData } = useGetCartItems();
  const isGift = shopCartData?.isGift ?? false;
  const [checkboxChecked, setCheckboxChecked] = useState<boolean>(isGift);
  const setIsGift = useSetIsGiftOnCart();
  const { trackCtGiftCheckbox } = useTrackGiftCheckbox();
  const locale = useLocale();

  const displayGiftingSection =
    isCartEligibleForGifting(shopCartData?.lineItems ?? [], shopCartData?.locale ?? '') &&
    ELIGIBLE_LOCALES_FOR_GIFTING_ENHANCEMENTS.includes(locale);

  const handleCheckbox = debounce(async () => {
    if (shopCartData) {
      trackCtGiftCheckbox(!checkboxChecked, 'cart', shopCartData);
      setCheckboxChecked(!checkboxChecked);
      await setIsGift({ shopCart: shopCartData, isGift: !checkboxChecked });
    }
  }, 500);

  return displayGiftingSection ? (
    <>
      <StyledContainer>
        <Flex>
          <StyledCheckbox
            data-test-id="gifting-checkbox"
            name="is-this-a-gift"
            labelText={cartContent?.giftingLabel?.value}
            checked={checkboxChecked}
            onChange={handleCheckbox}
          />
          <StyledIcon
            data-test-id="gifting-icon"
            name="gifting"
            height={16}
            primaryColor={grey[70]}
          />
        </Flex>
        {checkboxChecked && (
          <Description>
            <Support textColor={grey[90]}>
              {cartContent?.giftingDescription?.value}
            </Support>
          </Description>
        )}
      </StyledContainer>
      {checkboxChecked && <GiftingMembership />}
    </>
  ) : null;
};

export default Gifting;

const StyledContainer = styled.div`
  margin-top: ${spacing[24]};
  background-color: ${white};
  border-radius: ${spacing[4]};
  padding: ${spacing[16]} ${spacing[8]} ${spacing[16]} ${spacing[16]};
  color: ${black};
`;

const StyledCheckbox = styled(Checkbox)`
  width: 150px;
`;

const Description = styled.div`
  padding-top: ${spacing[8]};
  padding-left: ${spacing[24]};
`;

const StyledIcon = styled(Icon)`
  margin-top: ${spacing[4]};
  margin-left: ${spacing[8]};
`;
