import {
  grey,
  spacing,
  Badge,
  Eyebrow,
  Flex,
  ResponsiveImage,
  Support,
} from '@pelotoncycle/design-system';
import React from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import { Link } from '@ecomm/internationalize-ui';
import type { ProductDetailsCardProps } from '../types';

export type ProductPromoProps = Pick<ProductDetailsCardProps, 'promo'>;

export type ProductLinksProps = Pick<
  ProductDetailsCardProps,
  'primaryCTA' | 'secondaryCTA'
>;

export type ProductTextProps = Pick<
  ProductDetailsCardProps,
  'productName' | 'description'
>;

export const ProductDetailsCard: FC<ProductDetailsCardProps> = ({
  image,
  productName,
  description,
  primaryCTA,
  secondaryCTA,
  promo,
}) => {
  return (
    <Flex alignItems="flex-start" flexDirection="column">
      {promo && <ProductPromoBadge promo={promo} />}
      <StyledLink
        to={primaryCTA.link.url}
        href={primaryCTA.link.url}
        hasUnderline={false}
      >
        <Flex flexDirection="row" gap="62px">
          <ProductImage mobile={image.mobile} alt={image?.alt} />
          <Flex flexDirection="column" width="175px" gap={spacing[16]}>
            <ProductText productName={productName} description={description} />
            <ProductLinks primaryCTA={primaryCTA} secondaryCTA={secondaryCTA} />
          </Flex>
        </Flex>
      </StyledLink>
    </Flex>
  );
};

const ProductPromoBadge: FC<ProductPromoProps> = ({ promo }) => (
  <Badge variant="horizontal" theme="accent">
    {promo?.pillMessaging}
  </Badge>
);

const ProductText: FC<ProductTextProps> = ({ productName, description }) => (
  <Flex flexDirection="column" alignItems="flex-start" gap={spacing[4]}>
    <Eyebrow size="medium" textColor={grey[90]}>
      {productName}
    </Eyebrow>
    <Support size="medium" textColor={grey[70]}>
      {description}
    </Support>
  </Flex>
);

const ProductLinks: FC<ProductLinksProps> = ({ primaryCTA, secondaryCTA }) => (
  <Flex alignItems="flex-start" gap={spacing[16]}>
    <Link href={primaryCTA.link.url} to={primaryCTA.link.url} variant="body" size="small">
      {primaryCTA.text}
    </Link>
    {secondaryCTA && (
      <Link
        href={secondaryCTA?.link.url}
        to={secondaryCTA?.link.url}
        variant="body"
        size="small"
      >
        {secondaryCTA?.text}
      </Link>
    )}
  </Flex>
);

const StyledLink = styled(Link)`
  &:hover {
    h2 {
      color: ${grey[70]};
    }
  }
`;

const ProductImage = styled(ResponsiveImage)`
  img {
    max-width: ${spacing[120]};
    max-height: ${spacing[120]};
  }
`;
