import {
  Display,
  Flex,
  Label,
  spacing,
  white,
  grey,
  Badge,
} from '@pelotoncycle/design-system';
import React from 'react';
import { useMedia } from 'react-use';
import styled from 'styled-components';
import { BreakpointWidth } from '@peloton/styles/breakpoints';
import type { ProductDetailsCardProps } from '../types';

type ProductCardTextProps = {
  direction: 'horizontal' | 'vertical';
  productCard: ProductDetailsCardProps;
};

export const ProductCardText: React.FC<ProductCardTextProps> = ({
  direction,
  productCard,
}) => {
  const displayPromoBadgeText = productCard.promo?.pillMessaging || '';
  const isSmallScreen = useMedia(`(max-width: ${BreakpointWidth.smallScreen}px)`);
  return (
    <Flex
      flexDirection="column"
      gap={
        direction === 'horizontal'
          ? spacing[8]
          : { mobile: spacing[4], desktop: spacing[8] }
      }
      minWidth={direction === 'horizontal' ? '180px' : '178px'}
      maxWidth={direction === 'vertical' && displayPromoBadgeText ? '180px' : '140px'}
      data-test-id="product-card-text"
    >
      {displayPromoBadgeText && (
        <StyledBadge variant="horizontal" theme="accent" data-test-id="promo-badge">
          {displayPromoBadgeText}
        </StyledBadge>
      )}
      <Flex flexDirection="row" alignItems="center">
        <Display size="small" textColor={white} data-test-id="product-name">
          {productCard.hamburgerProductName}
        </Display>
      </Flex>
      <Label
        size="small"
        textColor={grey[30]}
        data-test-id="product-description"
        style={isSmallScreen ? { maxWidth: '138px', minHeight: '48px' } : undefined}
      >
        {productCard.description}
      </Label>
    </Flex>
  );
};

const StyledBadge = styled(Badge)`
  font-size: 13px !important;
  border-radius: ${spacing[4]}px;
  padding: ${spacing[4]}px ${spacing[8]}px;
  max-height: ${spacing[24]};
  width: fit-content;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
`;
