import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import FocusTrap from '@peloton/accessibility/FocusTrap';
import { isBrowser } from '@peloton/browser/identify';
import { theUserIsInAustria } from '@peloton/internationalize';
import { getCurrentRelativePathname } from '@peloton/internationalize/models/path';
import { bikePlus } from '@peloton/links/wwwPaths';
import { useLocale } from '@peloton/next/hooks/useLocale';
import { media } from '@peloton/styles/breakpoints';
import { useHamburgerNavProductNavs } from '@acme-ui/nav/ProductsMenu/useHamburgerNavProductNavs';
import { HeaderNavContext } from '@ecomm/header/HeaderNavProvider';
import keyboardTrap from '@ecomm/header/hooks/keyboardTrap';
import type { ProductsMenuItemRefs, ProductNavMeta } from '@ecomm/header/models';
import { Menu } from '@ecomm/header/models';
import useProductNavsMeta from '../hooks/useProductNavsMeta';
import MenuItemWithMegaMenu from './MenuItemWithMegaMenu';

const ProductsMenu: React.FC<React.PropsWithChildren<unknown>> = () => {
  const locale = useLocale();
  const isAustriaLocale = theUserIsInAustria(locale);

  const { currentMenu, focusedMenu, setFocusedMenu, hideMenu } = useContext(
    HeaderNavContext,
  );
  const availableProductNavs = useProductNavsMeta();

  const productNavs = useHamburgerNavProductNavs({
    availableProductNavs,
  });

  const activeMenu = getActiveMenu(
    isBrowser ? getCurrentRelativePathname()! : '/',
    productNavs,
    isAustriaLocale,
  );

  const wrapperRef = useRef<HTMLUListElement>(null);
  const menus = productNavs.map(nav => nav.name);
  const realignLastMegaMenu = productNavs.length > 5;
  const menuItemRefs: ProductsMenuItemRefs = {
    [Menu.Classes]: useRef(null),
    [Menu.Bike]: useRef(null),
    [Menu.Bikes]: useRef(null),
    [Menu.Tread]: useRef(null),
    [Menu.Treads]: useRef(null),
    [Menu.Row]: useRef(null),
    [Menu.App]: useRef(null),
    [Menu.Accessories]: useRef(null),
    [Menu.Apparel]: useRef(null),
    [Menu.Deals]: useRef(null),
    [Menu.Strength]: useRef(null), // guide-announce
    [Menu.RainforestCafe]: useRef(null), // TODO: baseline once guide is released
    /** @todo temporary for .at launch */
    [Menu.About]: useRef(null),
  };

  useEffect(
    keyboardTrap(
      wrapperRef,
      menus,
      currentMenu,
      focusedMenu,
      menuItemRefs,
      hideMenu,
      setFocusedMenu,
    ),
    [menus, currentMenu, focusedMenu],
  );

  return (
    <StyledFocusTrap disabled={!menus.includes(currentMenu)} className="focus-lock">
      <StyledMenuBar ref={wrapperRef} data-test-id={'productsMenuList'}>
        {productNavs.map(({ cmsId, subnavName }) => (
          <MenuItemWithMegaMenu
            key={cmsId}
            cmsId={cmsId}
            activeMenu={activeMenu}
            menuItemRefs={menuItemRefs}
            realignLastMegaMenu={realignLastMegaMenu}
            customSubnavName={subnavName}
          />
        ))}
      </StyledMenuBar>
    </StyledFocusTrap>
  );
};

export const getActiveMenu = (
  pathName: string,
  productNavs: ProductNavMeta[],
  isAustriaLocale: boolean,
): Menu => {
  const pageIndex = productNavs.findIndex(({ rootUrls }) =>
    rootUrls.find(url => url.test(pathName)),
  );

  if (pageIndex === -1) {
    return Menu.None;
  }

  if (isAustriaLocale && pathName === bikePlus) {
    return Menu.Bikes;
  }

  return productNavs[pageIndex].name;
};

export default ProductsMenu;

const StyledFocusTrap = styled(FocusTrap)`
  flex: 1;
  position: relative;

  ${media.desktopXLarge`
    padding: 0 2rem;
  `}
`;

export const StyledMenuBar = styled.ul`
  margin: 0 auto;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
