import { useState, useEffect } from 'react';
import { useTracking } from 'react-tracking';
import useGetCartItems from '@ecomm/commercetools/hooks/useGetCartItems';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { useConvertToMonolithCartForReferral } from '@ecomm/pg-checkout-commercetools/utils/cartUtils';
import { useMigrationStatus } from '@ecomm/pg-checkout-commercetools/utils/useMigrationStatus';
import {
  useHasCouponAutoApplied,
  useSetCouponCodeError,
  useSetHasCouponAutoApplied,
  useSetIsCartLoading,
} from '../context/CartContext';
import type { AddPromoCodeMutationResult } from '../graphql/mutations/AddPromoCode.generated';
import { useAddPromoCodeMutation } from '../graphql/mutations/AddPromoCode.generated';
import type { CartQuery } from '../graphql/queries/Cart.generated';
import { getStorageItem } from '../shared/getStorageItem';
import {
  SINGLE_CODE_PARAM,
  SINGLE_CODE_STORAGE_KEY,
  hasSingleDiscount,
} from './singleCodeUtils';

export enum Events {
  PageView = 'Viewed Shop Page with Single Code',
  PageViewError = 'Error Storing Single Code',
  AutoApply = 'Single Code Auto Applied',
  AutoApplyError = 'Error Auto Applying Single Code',
}

// Hook to handle single code from URL
export const useSingleCodeFromUrl = () => {
  const [single, setSingle] = useState<string | null>();
  const { trackEvent } = useTracking();
  const hasCouponAutoApplied = useHasCouponAutoApplied();
  const isSingleCodeEnabled = useIsToggleActive()('single_code_enabled');
  const { handleCartConversion } = useConvertToMonolithCartForReferral();
  const { buyFlowCheckoutEnabled } = useMigrationStatus();
  const storeSingleCodeToLocalStorage = async (singleCode: string) => {
    try {
      if (!buyFlowCheckoutEnabled) {
        await handleCartConversion();
      }
      localStorage.setItem(SINGLE_CODE_STORAGE_KEY, JSON.stringify(singleCode));
      trackEvent({ event: Events.PageView, properties: { singleCode } });
    } catch {
      trackEvent({ event: Events.PageViewError, properties: { singleCode } });
    }
  };

  useEffect(() => {
    if (hasCouponAutoApplied || !isSingleCodeEnabled) {
      return;
    }

    const params = new URLSearchParams(window.location.search);
    const singleCodeParam = params.get(SINGLE_CODE_PARAM);

    if (singleCodeParam) {
      storeSingleCodeToLocalStorage(singleCodeParam);
    }

    setSingle(getStorageItem(SINGLE_CODE_STORAGE_KEY) as string);
  }, [isSingleCodeEnabled, hasCouponAutoApplied]);

  return { hasCouponAutoApplied, singleCode: single };
};

// Hook to handle single code from storage
export const useSingleCodeFromStorage = (cartData: CartQuery | undefined) => {
  const isToggleActive = useIsToggleActive();
  const isProjectPhoenixEnabled = isToggleActive('projectPhoenix');
  const [addCouponToCart, { loading }] = useAddPromoCodeMutation();
  const [single, setSingle] = useState<string | null>();
  const setIsCartLoading = useSetIsCartLoading();
  const setCouponCodeError = useSetCouponCodeError();
  const setHasCouponAutoApplied = useSetHasCouponAutoApplied();
  const { trackEvent } = useTracking();
  const currentSingleCode = getStorageItem(SINGLE_CODE_STORAGE_KEY) as string;
  const { data: shopCart } = useGetCartItems();

  const removeSingleCodeFromLocalStorage = () => {
    localStorage.removeItem(SINGLE_CODE_STORAGE_KEY);
    setSingle(null);
    setHasCouponAutoApplied(true);
    setIsCartLoading(false);
  };

  const handleDomainError = (errorCode: string, singleCode: string) => {
    setCouponCodeError({ errorCode, couponCode: singleCode });
    trackEvent({ event: Events.AutoApplyError, properties: { singleCode, errorCode } });
  };

  const handleCartUpdate = (response: AddPromoCodeMutationResult, singleCode: string) => {
    const mutationData = response?.data;
    if (mutationData?.addCouponToCart?.__typename === 'DomainError') {
      const errorCode = mutationData.addCouponToCart.code;
      handleDomainError(errorCode, singleCode);
    } else {
      trackEvent({ event: Events.AutoApply, properties: { singleCode } });
    }
    removeSingleCodeFromLocalStorage();
  };

  useEffect(() => {
    if (currentSingleCode !== single) {
      setSingle(currentSingleCode);
    }
  }, [currentSingleCode, single]);

  useEffect(() => {
    if (loading) {
      setIsCartLoading(true);
    }
  }, [loading, setIsCartLoading]);

  useEffect(() => {
    if (shopCart?.discounts && hasSingleDiscount(shopCart.discounts) && single) {
      removeSingleCodeFromLocalStorage();
    }
  }, [shopCart, single]);

  useEffect(() => {
    if (!single || loading || !cartData?.cart) {
      return;
    }

    const cart = cartData.cart;
    const singleInStorage = getStorageItem(SINGLE_CODE_STORAGE_KEY);
    if (Boolean(cart?.hasEquipment && singleInStorage)) {
      addCouponToCart({
        variables: {
          couponCode: single,
          cartId: cart.id,
          calculateEstimatedShippingPrice: isProjectPhoenixEnabled,
        },
      }).then((response: AddPromoCodeMutationResult) =>
        handleCartUpdate(response, single),
      );
    }
  }, [
    addCouponToCart,
    cartData,
    isProjectPhoenixEnabled,
    loading,
    single,
    setCouponCodeError,
    setHasCouponAutoApplied,
    setIsCartLoading,
    trackEvent,
  ]);
};
