import { grey, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import {
  sortedLineItems,
  ALL_ACCESS_MEMBERSHIP,
  hasCFUExceptGuide,
  getAAMProduct,
} from '@ecomm/checkout-commercetools/helpers/ct-cart-helper';
import useGetCartItems from '@ecomm/commercetools/hooks/useGetCartItems';
import useIsMembershipLineItemExperimentActive from '@ecomm/feature-toggle/MembershipLineItem';
import type { LineItemDataFragment } from '@ecomm/shop-cart/graphql/fragments.generated';
import AllAccessMembershipView from './AllAccessMembershipView';
import type { RemoveItemArgs } from './CartSummary';
import GuideMembershipView from './GuideMembershipView';
import RemovableCartItemView from './RemovableCartItemView';

type Props = {
  removeItem: ({ id, isBundle, isBundleCFU }: RemoveItemArgs) => void;
};

const CartItems: React.FC<React.PropsWithChildren<Props>> = ({ removeItem }) => {
  const { data: cartItems } = useGetCartItems();
  const isMembershipLineItemExperimentActive = useIsMembershipLineItemExperimentActive(
    Boolean(cartItems?.isGift),
  );
  const cartItemList = cartItems?.lineItems?.length
    ? sortedLineItems(cartItems.lineItems)
    : [];

  /* AAM products are to be displayed as the last item in the CFU list, so filtering out here */
  const nonMembershipItems = cartItemList.filter(
    (listItem: LineItemDataFragment) =>
      listItem.productVariant.type !== ALL_ACCESS_MEMBERSHIP,
  );

  const showAAM =
    hasCFUExceptGuide(cartItemList) && !isMembershipLineItemExperimentActive;
  const AAMProduct = getAAMProduct(cartItemList);

  return (
    <>
      {nonMembershipItems.map((item: LineItemDataFragment, i) => {
        return (
          <StyledRemovableCartItemView
            cartItem={item}
            key={item.id}
            removeItem={removeItem}
          />
        );
      })}
      {showAAM && (
        <StyledMembership isProductRemovable={false}>
          <Divider />
          <AllAccessMembershipView membershipItem={AAMProduct} removeItem={removeItem} />
        </StyledMembership>
      )}

      <StyledGuideMembershipView />
    </>
  );
};

const Divider = styled.hr`
  border: none;
  background-color: ${grey[40]};
  height: 1px;
  margin-bottom: ${spacing[24]};
`;

const StyledRemovableCartItemView = styled(RemovableCartItemView)`
  &:first-of-type {
    border-top: none;
  }

  &:last-of-type {
    border-bottom: 1px solid ${grey[50]};
  }
`;

const StyledMembership = styled.div<{ isProductRemovable: boolean }>`
  ${props =>
    props.isProductRemovable === true
      ? `
        border-top: 1px solid ${grey[40]};
        padding: ${spacing[24]} 0;
      `
      : ''}
`;

const StyledGuideMembershipView = styled(GuideMembershipView)`
  margin-bottom: ${spacing[24]};
`;

export default CartItems;
