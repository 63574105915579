import React, { createContext } from 'react';
import type { AvailabilityState, AvailabilityByProduct, ProductStates } from './models';
import type { Product } from './models/product';

export type AvailabilityByProductOrNull = AvailabilityByProduct | null;

export type VerifyProductState = (p: Product, states: ProductStates[]) => boolean;

export type ProductStatesFunctions = {
  isRouteAvailable: (url: string) => boolean;
  verifyProductState: VerifyProductState;
  verifyAvailability: (
    p: Product,
    predicate: (a: AvailabilityState) => boolean,
  ) => boolean;
  isProductUnavailableSoldOut: (p: Product) => boolean;
  isProductUnavailableMarketingLeadCapture: (p: Product) => boolean;
  isProductAvailableForMarketingPages: (p: Product) => boolean;
  isProductAvailableForShopPages: (p: Product) => boolean;
  isProductAvailableForPurchase: (p: Product) => boolean;
  isProductUnavailableForPurchase: (p: Product) => boolean;
  isProductAvailableForPurchaseOrSoldOut: (p: Product) => boolean;
  isProductAvailableForShopLeadCapture: (p: Product) => boolean;
  isRouteControlledByProductToggles: (url: string) => boolean;
  isAnyCFUAvailableForPurchase: () => boolean;
  productStates: AvailabilityByProduct;
  setProductStates: (
    value:
      | AvailabilityByProductOrNull
      | ((val: AvailabilityByProductOrNull) => AvailabilityByProductOrNull)
      | null,
  ) => void;
};

export type ProductStatesProperties = {
  areStatesOverridden: boolean;
};

// we need to initialise this as any even though it can only actually be used when the value
// is initialised
export const ProductStatesContext = createContext<
  ProductStatesFunctions & ProductStatesProperties
>(null as any);

export const useProductStates = (): ProductStatesFunctions & ProductStatesProperties => {
  const context = React.useContext(ProductStatesContext);

  if (context === undefined) {
    throw new Error('useProductStates must be used within a ProductStatesContext');
  }

  return context;
};
