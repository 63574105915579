import { spacing } from '@pelotoncycle/design-system';
import { css, keyframes } from 'styled-components';

export const NAV_HEIGHT_MOBILE = spacing[48];
export const NAV_HEIGHT_TABLET_XLARGE = spacing[64];
export const NAV_HEIGHT_DESKTOP_LARGE = spacing[72];
export const MENU_ARROW_ICON_HEIGHT = 16;
export const MENU_ARROW_ICON_WIDTH = spacing[24];

export const baseTopNavStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  cursor: pointer;
`;

const topNavFadeInKeyFrames = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const topNavAnimation = css`
  animation: ${topNavFadeInKeyFrames} 600ms;
`;

export const navHoverOpacity = css`
  &:hover {
    opacity: 0.6; // need to get from design
  }
`;
