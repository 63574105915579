import { useState, useEffect } from 'react';
import { useTracking } from 'react-tracking';
import type { Country, Locale } from '@peloton/internationalize';
import { useLocale } from '@peloton/internationalize';
import { toLocaleFromCountry } from '@peloton/internationalize/models/locale';
import { LOCALE_TOGGLE } from '@peloton/internationalize/models/toggle';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import {
  domainSwitcher,
  i18nUrlSwitcher,
  isGeoModalDisabled,
  isRedirectFromGeomodal,
  resetURLFromGeomodal,
  sameLocale,
  setPreviouslyHidden,
  wasPreviouslyHidden,
} from '../geoModalV2Helpers';

export enum GeoModalLocaleSwitch {
  footer = 'footer',
  modal = 'over the page',
}

export const useGeoModal = (): {
  shouldDisplay: boolean;
  closeGeoModal: () => void;
  switchApplication: (
    localePreference: Locale,
    modulePlacement: GeoModalLocaleSwitch,
    currentUrl?: URL | null,
  ) => void;
  applicationLocale: Locale;
  assumedCountry: Country | null;
  setShouldDisplay: (setShouldDisplay: boolean) => void;
} => {
  const { trackEvent } = useTracking();
  const [assumedCountry, setAssumedCountry] = useState<Country | null>(null);
  const [shouldDisplay, setShouldDisplay] = useState(false);
  const applicationLocale = useLocale();
  const isToggleActive = useIsToggleActive();
  const i18nSubpathGeoModal = isToggleActive('i18nsubpathgeomodal');

  const closeGeoModal = () => {
    setPreviouslyHidden();
    setShouldDisplay(false);
  };

  const switchApplication = (
    localePreference: Locale,
    modulePlacement: GeoModalLocaleSwitch,
    currentUrl: URL | null = new URL(window.location.href),
  ) => {
    const originatingStore = applicationLocale;
    const destinationStore = localePreference;

    if (currentUrl) {
      trackEvent({
        event: 'User switched stores',
        properties: {
          originatingStore,
          destinationStore,
          modulePlacement,
        },
      });

      // NOTE: applicationLocale is the current locale. localePreference is the locale we are changing to
      const domainUrl = domainSwitcher(currentUrl, applicationLocale, localePreference);
      const i18nUrl = i18nUrlSwitcher(currentUrl, localePreference);
      const newUrl = LOCALE_TOGGLE && i18nSubpathGeoModal ? i18nUrl : domainUrl;
      window.location.assign(newUrl);
    }
  };

  useEffect(() => {
    if ((window as any).peloton && (window as any).peloton.country) {
      setAssumedCountry((window as any).peloton.country);
    }
  }, []);

  useEffect(() => {
    const geoModalDisabled = isGeoModalDisabled();
    const previouslyHidden = wasPreviouslyHidden();
    const isRedirect = isRedirectFromGeomodal();
    const isSameLocale = sameLocale(
      assumedCountry ? toLocaleFromCountry(assumedCountry) : null,
      applicationLocale,
    );

    setShouldDisplay(
      !geoModalDisabled && !previouslyHidden && !isRedirect && !isSameLocale,
    );

    if (!geoModalDisabled && isRedirect) {
      if (!previouslyHidden) {
        setPreviouslyHidden();
      }
      resetURLFromGeomodal();
    }
  }, [assumedCountry, applicationLocale]);

  return {
    shouldDisplay,
    closeGeoModal,
    applicationLocale,
    assumedCountry,
    switchApplication,
    setShouldDisplay,
  };
};
