import {
  black,
  white,
  spacing,
  Container,
  Eyebrow,
  Flex,
} from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import { Link } from '@ecomm/internationalize-ui';
import { NAV_HEIGHT_DESKTOP_LARGE, topNavAnimation } from '../styles';
import type { GeoPickerProps } from '../types';

export const GeoPickerMenu: FC<Pick<GeoPickerProps, 'eyebrowText' | 'links'>> = ({
  eyebrowText,
  links,
}) => {
  return (
    <StyledGeoPickerMenuContainer>
      <Eyebrow size="small">{eyebrowText}</Eyebrow>
      <Flex flexDirection="column" padding={`${spacing[24]} 0 ${spacing[32]}`} gap="20px">
        {links?.map(link => (
          <StyledLink
            to={link.link.url}
            href={link.link.url}
            key={link.text}
            hasUnderline={false}
          >
            {link.text}
          </StyledLink>
        ))}
      </Flex>
    </StyledGeoPickerMenuContainer>
  );
};

const StyledGeoPickerMenuContainer = styled(Container)`
  position: absolute;
  top: ${NAV_HEIGHT_DESKTOP_LARGE};
  left: 50%;
  width: 395px;
  padding: ${spacing[40]} 0 0 ${spacing[40]};
  background-color: ${white};
  border-radius: ${spacing[4]};
  box-shadow: 0 30px 70px 0 ${rgba(black, 0.11)};
  ${topNavAnimation}
  transform: translateX(-50%);
`;

const StyledLink = styled(Link)`
  span {
    font-weight: 300;
  }
`;
