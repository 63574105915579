import type { Locale } from '@peloton/internationalize';
import { useIsPreviewCopyEnabled } from '@content/client/hooks';
import {
  fetchPageBuilderContentAggregate,
  fetchWWWBuilderContentAggregate,
} from '@content/client/lib/fetchBuilderContentAggregate';
import { useIsNavRefactorEnabled } from '@ecomm/feature-toggle/NavRefactor';
import useContentAggregate from '../aggregates/useContentAggregate';
import nav from './nav';
import pageBuilderNav from './pageBuilderNav';
// When preview copy is enabled, we don't pass a custom fetcher so that we can use the default fetcher,
// which goes straight to contentful
// When preview copy is *not* enabled, we pass a custom fetcher that fetches from the builder function
const useCustomFetcher = (locale: Locale, isNavRefactorEnabled: boolean) => {
  const preview = useIsPreviewCopyEnabled();

  // Need to enable use of page builder fetcher when preview copy is enabled
  if (isNavRefactorEnabled) {
    return () => fetchPageBuilderContentAggregate(pageBuilderNav.entryId, locale);
  }

  if (preview) {
    return undefined;
  }

  return () => {
    return fetchWWWBuilderContentAggregate(nav.entryId, locale);
  };
};

const useNav = (locale: Locale): any => {
  const isNavRefactorEnabled = useIsNavRefactorEnabled();
  const customFetcher = useCustomFetcher(locale, isNavRefactorEnabled);
  const entryId = isNavRefactorEnabled ? pageBuilderNav.entryId : nav.entryId;
  return useContentAggregate(entryId, false, locale, customFetcher);
};

export default useNav;
