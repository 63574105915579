import { Partners } from '@ecomm/financing/models/Partners';
import { usePrograms } from '@ecomm/programs/Context';
import useBundleFinancing from '@ecomm/shop/hooks/useBundleFinancing';
import useCartWithVariables from './useCartWithVariables';

const useIsCartEligibleForFinancing = (): boolean => {
  const { financingPartner } = usePrograms();
  const hasFinancingPartner = financingPartner !== Partners.None;
  const cart = useCartWithVariables().data?.cart;
  const hasEquipment = cart?.hasEquipment;
  const hasEquipmentLease = cart?.hasEquipmentLease;
  const { financingState } = useBundleFinancing();
  const hasRow = cart?.hasRow && financingState?.row;
  const hasTread = cart?.hasTread && financingState?.tread;
  const hasGuide = cart?.hasGuide && financingState?.guide;
  const hasRefurb = cart?.hasRefurbBike && financingState?.['refurbished-bike'];
  const hasRefurbPlus =
    cart?.hasRefurbBikePlus && financingState?.['refurbished-bike-plus'];
  const hasBike = cart?.hasBike && financingState?.bike;
  const hasBikePlus = cart?.hasBikePlus && financingState?.['bike-plus'];
  const hasTreadPlus = cart?.hasTreadPlus && financingState?.['tread-plus'];
  const isAnyBundleInCartFinancingAvailible =
    hasRow ||
    hasTread ||
    hasGuide ||
    hasRefurb ||
    hasRefurbPlus ||
    hasBike ||
    hasBikePlus ||
    hasTreadPlus;

  return Boolean(
    hasFinancingPartner &&
      hasEquipment &&
      !hasEquipmentLease &&
      isAnyBundleInCartFinancingAvailible,
  );
};

export default useIsCartEligibleForFinancing;
