import useIsToggleActive from './hooks/useIsToggleActive';
import useToggleVariables from './hooks/useToggleVariables';
import useToggleVariation from './hooks/useToggleVariation';

const CTA_COPY_TEST_FLAG = 'cta_copy_test';

export const useCtaCopyTestToggle = () => {
  const isExperimentActive = useIsToggleActive()(CTA_COPY_TEST_FLAG);
  const variables = useToggleVariables(CTA_COPY_TEST_FLAG);
  const activeVariation = useToggleVariation(CTA_COPY_TEST_FLAG);

  return {
    isExperimentActive,
    variables: {
      shop: variables?.shop || {},
      explore: variables?.explore || {},
      exclusions: variables?.exclusions?.keyWords || [],
    },
    activeVariation,
  };
};
