import { grey, Icon, spacing, Tooltip } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import useCartContent from '@content/client/www/cart/useCart';
import { isCFUbundle } from '@ecomm/checkout-commercetools/helpers/ct-cart-helper';
import { useAddItemToCart } from '@ecomm/pg-checkout-commercetools/utils/cartUtils';
import { Type } from '@ecomm/pg-checkout-commercetools/utils/types';
import type { LineItemDataFragment } from '@ecomm/shop-cart/graphql/fragments.generated';
import type { RemoveItemArgs } from './CartSummary';

type Props = {
  cartItem: LineItemDataFragment;
  removeItem: ({ id, isBundle, isBundleCFU, quantity }: RemoveItemArgs) => void;
};

export const IncrementDecrement: React.FC<React.PropsWithChildren<Props>> = ({
  cartItem,
  removeItem,
}) => {
  const id = cartItem.id ?? '';
  const itemQty = cartItem.quantity ?? 0;
  const sku = cartItem.productVariant?.sku ?? '';
  const bundleItemLineItems = cartItem.bundleItemLineItems ?? [];
  const isBundle = bundleItemLineItems.length > 0;
  const isBundleCFU = isBundle && isCFUbundle(cartItem);
  const { addItemToCart } = useAddItemToCart();
  const { content: cartContent } = useCartContent();

  const handleDecrementClick: React.MouseEventHandler<HTMLSpanElement> = ():
    | false
    | void => {
    return itemQty === 1 ? false : removeItem({ id, isBundle, isBundleCFU, quantity: 1 });
  };

  const handleDecrementKeyDown: React.KeyboardEventHandler<HTMLSpanElement> = (
    event,
  ): false | void => {
    return itemQty === 1
      ? false
      : event?.key === 'Enter' && removeItem({ id, isBundle, isBundleCFU, quantity: 1 });
  };

  return (
    <div>
      {isBundle ? (
        <span data-test-id="icons-qty-bundles">
          <span data-test-id="bundle-minus-icon">
            <Icons
              aria-label="minus icon disabled state"
              tabIndex={0}
              height={14}
              primaryColor={grey[50]}
              name="minus"
            />
          </span>
          <ItemQty data-test-id="icons-qty">{itemQty}</ItemQty>
          {/* using a tooltip until we have the functionality to open the model using plus icon ?? */}
          <Tooltip data-test-id="bundle-tooltip" thin trigger="click" position="bottom">
            {cartContent?.plusIconInfo?.value}
          </Tooltip>
        </span>
      ) : (
        <span data-test-id="icons-qty-non-bundles">
          <span
            data-test-id="non-bundle-minus-icon"
            role="button"
            tabIndex={0}
            onKeyDown={handleDecrementKeyDown}
            onClick={handleDecrementClick}
          >
            <Icons
              aria-label="minus"
              height={14}
              primaryColor={itemQty === 1 ? `${grey[50]}` : `${grey[90]}`}
              name="minus"
            />
          </span>
          <ItemQty data-test-id="icons-qty">{itemQty}</ItemQty>
          <span
            role="button"
            data-test-id="non-bundle-plus-icon"
            tabIndex={0}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                addItemToCart({
                  type: Type.ACCESSORY,
                  sku: sku,
                  callBack: () => {},
                });
              }
            }}
            onClick={() => {
              addItemToCart({
                type: Type.ACCESSORY,
                sku: sku,
                callBack: () => {},
              });
            }}
          >
            <Icons
              height={14}
              primaryColor={`${grey[90]}`}
              strokeWidth="1.5"
              name="plus"
            />
          </span>
        </span>
      )}
    </div>
  );
};

const Icons = styled(Icon)`
  margin-top: ${spacing[8]};
`;

const ItemQty = styled.span`
  padding: ${spacing[12]};
  margin-top: ${spacing[16]};
`;
