import type { ProductNavMeta } from '@ecomm/header/models';
import { ACCESORIES_PRODUCT_NAV_KEY, CLASSES_PRODUCT_NAV_KEY } from '../constants';
import { useIsUserInUnitedStates } from '../hooks/useIsUserInUnitedStates';

interface UseHamburgetNavProductNavsParams {
  availableProductNavs: ProductNavMeta[];
}
/**
 * This hook re-arranges the order of the product nav by moving classes before accessories
 */
export const useHamburgerNavProductNavs = ({
  availableProductNavs,
}: UseHamburgetNavProductNavsParams) => {
  const isUSLocale = useIsUserInUnitedStates();

  if (!isUSLocale) {
    return availableProductNavs;
  }

  let productNavs = availableProductNavs;

  const classesProductNav = availableProductNavs.find(
    ({ cmsId }) => cmsId === CLASSES_PRODUCT_NAV_KEY,
  );
  const productNavsCopy = availableProductNavs.filter(
    ({ cmsId }) => cmsId !== CLASSES_PRODUCT_NAV_KEY,
  );
  const accessoriesIndex = productNavsCopy.findIndex(
    ({ cmsId }) => cmsId === ACCESORIES_PRODUCT_NAV_KEY,
  );

  productNavs = classesProductNav
    ? [
        ...productNavsCopy.slice(0, accessoriesIndex),
        classesProductNav,
        ...productNavsCopy.slice(accessoriesIndex),
      ]
    : availableProductNavs;
  return productNavs;
};
