import { toLocaleFromTLD } from '@peloton/internationalize/models/locale';
import { toCurrencyfromLocale } from '@ecomm/graphql-bridge';
import type { ProductSelection } from '@ecomm/graphql/types.generated';
import { getCtVariantBySelection } from '@ecomm/shop/commercetools/getCtVariantBySelection';

const useDynamicVariantsBySelectionsQuery = () => {
  const baseLocale = toLocaleFromTLD();
  const currency = toCurrencyfromLocale(baseLocale);

  return async (productSelections: ProductSelection[]) => {
    return getCtVariantBySelection(baseLocale, currency, productSelections);
  };
};

export default useDynamicVariantsBySelectionsQuery;
