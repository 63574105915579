import { Label } from '@pelotoncycle/design-system';
import React from 'react';
import PriceWithCents from '../../shared/PriceWithCents';
import LeasingBundlePrice from './LeasingBundlePrice';

type Props = {
  priceInCents: number;
  isMembership: boolean;
  isLeasingBundle: boolean;
  className?: string;
};

const ItemPriceView: React.FC<React.PropsWithChildren<Props>> = ({
  priceInCents,
  isMembership,
  isLeasingBundle,
  className,
}) => {
  if (isMembership) {
    return null;
  } else if (isLeasingBundle) {
    return <LeasingBundlePrice priceInCents={priceInCents} />;
  } else {
    return (
      <Label className={className} data-test-id="item-price" size="large">
        <PriceWithCents amount={priceInCents} />
      </Label>
    );
  }
};

export default ItemPriceView;
