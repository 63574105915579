import {
  Flex,
  Label,
  brand,
  white,
  red,
  spacing,
  Icon,
  green,
} from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import PriceWithCents from '../../shared/PriceWithCents';
import RemoveButton from '../../shared/RemoveButton';

type Props = {
  code: string;
  amount: number;
  isLoading?: boolean;
  removeButtonDisabled?: boolean;
  onRemove?: () => void;
};

const DiscountView: React.FC<React.PropsWithChildren<Props>> = ({
  code,
  amount,
  isLoading,
  removeButtonDisabled,
  onRemove,
}) => {
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Flex alignItems="center">
        <Flex backgroundColor={brand.darkest} padding={`${spacing[2]} ${spacing[4]}`}>
          <Label size="small" textColor={white} as="span">
            {code}
          </Label>
        </Flex>
        <Flex padding={`0 ${spacing[8]}`} gap={spacing[4]} alignItems="center">
          <RemoveButton size="small" onClick={onRemove} disabled={removeButtonDisabled} />
          {isLoading && (
            <StyledSpinner
              data-test-id="remove-coupon-loading-spinner"
              name="spinner"
              height={30}
              primaryColor={red[20]}
            />
          )}
        </Flex>
      </Flex>

      <Label size="large" textColor={green[50]} as="span">
        <PriceWithCents amount={amount !== 0 ? -amount : amount} />
      </Label>
    </Flex>
  );
};

const StyledSpinner = styled(Icon)`
  margin-left: ${spacing[8]};
`;

export default DiscountView;
