import { spacing, grey, white } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { TrackingEvent } from '@ecomm/analytics/models';
import { Link } from '@ecomm/internationalize-ui';
import type { ProductDetailsCardProps } from '../types';

type ShopButtonProps = {
  productCard: ProductDetailsCardProps;
};

export const ShopButton: React.FC<ShopButtonProps> = ({ productCard }) => {
  const { trackEvent } = useTracking();
  const text = productCard?.secondaryCTA?.text ?? productCard?.primaryCTA?.text;
  const url = productCard?.secondaryCTA?.link?.url ?? productCard?.primaryCTA?.link?.url;

  const trackShopButtonClicked = () => {
    trackEvent({
      event: TrackingEvent.ClickedNavigation,
      properties: {
        category: productCard?.productName,
        linkTo: url,
        linkName: `Hamburger Menu - ${text}`,
      },
    });
  };

  return (
    <StyledButton
      data-test-id={`styled-button`}
      to={url}
      href={url}
      onClick={trackShopButtonClicked}
    >
      <StyledLabel>{text}</StyledLabel>
    </StyledButton>
  );
};

const StyledButton = styled(Link)`
  margin: 0;
  backdrop-filter: blur(12px);
  background: ${rgba(grey[70], 0.5)};
  border-radius: 100px;
  border: none;
  display: flex;
  justify-content: center;
  gap: ${spacing[8]};
  transition: all 300ms ease-in-out;
  width: 100%;
  outline: none;
  padding: 0;
  cursor: pointer;

  &:hover {
    background-color: ${white};
    p {
      color: ${grey[90]};
    }
  }
`;

const StyledLabel = styled.p`
  display: flex;
  font-weight: 600;
  height: 44px;
  align-items: center;
  font-size: 0.938rem;
  color: ${white};
  margin: 0;
`;

export default ShopButton;
