import { spacing } from '@pelotoncycle/design-system';
import React from 'react';
import { MENU_ARROW_ICON_HEIGHT, MENU_ARROW_ICON_WIDTH } from '../styles';

interface NavMenuArrowProps extends React.SVGProps<SVGSVGElement> {
  isMenuOpen: boolean;
}

const NavMenuArrow: React.FC<React.PropsWithChildren<NavMenuArrowProps>> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={MENU_ARROW_ICON_WIDTH}
    height={spacing[MENU_ARROW_ICON_HEIGHT]}
    viewBox="0 0 17 17"
    fill="none"
    opacity={props.isMenuOpen ? 1 : 0}
    style={{
      position: 'absolute',
      bottom: `-${MENU_ARROW_ICON_HEIGHT / 2}px`,
      transition: props.isMenuOpen ? 'opacity 600ms' : '',
    }}
    {...props}
  >
    <path
      d="M8.43201 0L16.864 8.43198L8.43201 16.864L3.02426e-05 8.43198L8.43201 0Z"
      fill="white"
    />
  </svg>
);

export { NavMenuArrow };
