import type { Locale } from '@peloton/internationalize/models/locale';
import {
  parseAggregateData,
  parsePageBuilderAggregateData,
} from '../utils/parseAggregateData';
import fetchBuilderData from './fetchBuilderData';
import fetchWWWBuilderData from './fetchWWWBuilderData';

export const fetchBuilderContentAggregate = async (entryId: string, locale: Locale) => {
  const peloContentBuilderPath = `/peloContentEntryHandler/${locale}/${entryId}`;
  const globalComponentData = await fetchBuilderData(peloContentBuilderPath);
  return parseAggregateData(globalComponentData, entryId);
};

export const fetchWWWBuilderContentAggregate = async (
  entryId: string,
  locale: Locale,
) => {
  const peloContentBuilderPath = `/api/pelo-content-entry-handler/${locale}/${entryId}`;
  const globalComponentData = await fetchWWWBuilderData(peloContentBuilderPath);
  return parseAggregateData(globalComponentData, entryId);
};

export const fetchPageBuilderContentAggregate = async (
  entryId: string,
  locale: Locale,
) => {
  const pageBuilderPath = `/api/page-builder-entry-handler/${locale}/${entryId}`;
  const globalComponentData = await fetchWWWBuilderData(pageBuilderPath);
  return parsePageBuilderAggregateData(globalComponentData, entryId);
};
