import { Button, grey, white, accessibility, spacing } from '@pelotoncycle/design-system';
import type { RefObject } from 'react';
import React from 'react';
import styled from 'styled-components';
import { defaultTransition, hover, media, underlineActive } from '@peloton/styles';
import { Chevron, Orientation } from '@ecomm/icons';
import HeaderText from './HeaderText';
import { fadeInAnimation } from './NavAnimations';

type StyledMenuItemProps = {
  text?: string;
  testId: string;
  isActive: boolean;
  expanded?: boolean;
  onClick?: any;
  itemRef: RefObject<HTMLButtonElement>;
};

const MenuItem: React.FC<React.PropsWithChildren<StyledMenuItemProps>> = ({
  text,
  testId,
  isActive,
  expanded = false,
  onClick,
  itemRef,
}) => {
  return (
    <li>
      <StyledMenuItem
        aria-expanded={expanded}
        data-test-id={`${testId}`}
        onClick={onClick}
        ref={itemRef}
      >
        <Text expanded={expanded} isActive={isActive}>
          {text}
        </Text>
        <Chevron
          aria-hidden="true"
          height={10}
          width={10}
          orientation={expanded ? Orientation.Up : Orientation.Down}
        />
      </StyledMenuItem>
    </li>
  );
};

export default MenuItem;

const StyledMenuItem = styled(Button)`
  background-color: transparent !important;
  border-color: transparent !important;
  color: ${white};
  width: auto !important;
  height: 56px !important;
  padding: 0 16px !important;
  display: inline-block;
  cursor: pointer;
  display: flex;
  gap: ${spacing[4]};
  ${fadeInAnimation};

  ${media.desktopLarge`    
    padding: 0 12px !important;
  `};

  ${media.desktopXLarge`
    padding: 0 20px !important;
  `};

  ${defaultTransition()}
  ${hover`
    color: ${grey[50]}
  `}

  svg {
    fill: ${grey[50]} !important;
    margin: -2px 0 0 3px !important;
    pointer-events: none;
    transition-property: transform;
    transition-duration: 250ms;
    transition-timing-function: ease;
  }

  &:focus-visible {
    background-color: ${grey[70]};
    outline: ${spacing[2]} solid ${accessibility.outline};
  }
`;

const Text = styled(HeaderText)<{ expanded: boolean; isActive: boolean }>`
  position: inherit !important;
  ${props =>
    !props.isActive
      ? null
      : `${underlineActive}
    &::after {
      color: ${white};
    }
`}
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #fff;
    bottom: -25px;
    left: 50%;
    transform: rotate(90deg);
    opacity: ${props => (props.expanded ? 1 : 0)};
    transition: ${props => (props.expanded ? 'opacity 600ms' : '')};
  }
`;
