import { grey, white, Icon, spacing, Badge } from '@pelotoncycle/design-system';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import type { ProductNavData } from '@ecomm/copy/helpers/types';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { Link } from '@ecomm/internationalize-ui';
import { CtaButtonComponent } from './CtaSection';

type Props = {
  categoryId: string;
  products: ProductNavData['products'];
  handleClose: () => void;
};

const PrimaryLinks: React.FC<React.PropsWithChildren<Props>> = ({
  categoryId,
  products,
  handleClose,
}) => {
  const { trackEvent } = useTracking();
  const productsWithCTA = React.useMemo(
    () => products.filter(({ primaryCta }) => !!primaryCta),
    [products],
  );

  const isNavBadgingUpdatesEnabled = useIsToggleActive()('intl_promo_end');

  const isAppAndStrength =
    useIsToggleActive()('showStrengthPlusInNav') && categoryId === 'nav.app';

  return (
    <PrimaryLinksContainer>
      {productsWithCTA.map(
        ({
          key,
          productName,
          promoBadgeText,
          promoChangeText,
          primaryCta: { key: primaryCtaKey, url },
          secondaryCta,
        }) => {
          const setPromoBadgeText =
            promoBadgeText && promoBadgeText !== 'hide' ? promoBadgeText : '';

          const setPromoChangeText =
            promoChangeText && promoChangeText !== 'hide' ? promoChangeText : '';

          const displayPromoBadgeText = isNavBadgingUpdatesEnabled
            ? setPromoBadgeText
            : setPromoChangeText;

          return (
            <li key={key}>
              <ClickableContainer
                onClick={() => {
                  trackEvent({
                    properties: {
                      category: key,
                      parent: key,
                      unitName: primaryCtaKey,
                      linkTo: url,
                      linkName: productName,
                    },
                  });
                  window.location.href = url;
                  handleClose();
                }}
              >
                <PrimaryLink
                  to={url}
                  href={url}
                  target="_self"
                  children={productName}
                  hasUnderline={false}
                />
                {displayPromoBadgeText && (
                  <StyledBadge variant="horizontal" theme="accent">
                    {displayPromoBadgeText}
                  </StyledBadge>
                )}
                <StyledChevron
                  name="chevron"
                  primaryColor={grey[50]}
                  aria-hidden={true}
                  rotate={180}
                  height={12}
                />
              </ClickableContainer>
              {secondaryCta && isAppAndStrength ? (
                <CtaButtonComponent
                  categoryId={categoryId}
                  cta={secondaryCta}
                  contentfulKey={key}
                  handleClose={handleClose}
                />
              ) : null}
            </li>
          );
        },
      )}
    </PrimaryLinksContainer>
  );
};

export default PrimaryLinks;

const PrimaryLink = styled(Link)`
  color: ${white};
  margin-right: ${spacing[4]};
  span {
    font-size: 18px;
    line-height: 1.375em;
  }
`;

const PrimaryLinksContainer = styled.ul`
  color: ${white};
  button {
    display: flex;
    align-items: center;
    margin: 1.25rem 0;
  }
`;

const StyledChevron = styled(Icon)`
  margin-left: ${spacing[12]};
`;

const StyledBadge = styled(Badge)`
  font-size: 13px;
  border-radius: ${spacing[4]}px;
  padding: ${spacing[4]}px ${spacing[8]}px;
  margin-left: ${spacing[12]};
`;

const ClickableContainer = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
`;
