import { grey, spacing, Container } from '@pelotoncycle/design-system';
import React from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import { HomeLogo } from '../HomeLogo';
import { IconRow } from '../IconRow';
import { NAV_HEIGHT_MOBILE } from '../styles';
import type {
  AccountProps,
  CartProps,
  GeoPickerProps,
  HamburgerMenuProps,
  ProductSubNavProps,
} from '../types';

export type MobileNavProps = {
  productSubNavs: ProductSubNavProps[];
  geoPickerMenu?: GeoPickerProps;
  accountMenu?: AccountProps;
  cart?: CartProps;
  hamburgerMenu?: HamburgerMenuProps;
};

export const MobileNav: FC<MobileNavProps> = ({
  productSubNavs,
  geoPickerMenu,
  accountMenu,
  cart,
  hamburgerMenu,
}) => {
  return (
    <StyledMobileNavContainer data-test-id="mobileHeader">
      <HomeLogo />
      <IconRow
        accountMenu={accountMenu}
        geoPickerMenu={geoPickerMenu}
        cart={cart}
        hamburgerMenu={hamburgerMenu}
        productSubNavs={productSubNavs}
      />
    </StyledMobileNavContainer>
  );
};

const StyledMobileNavContainer = styled(Container)`
  height: ${NAV_HEIGHT_MOBILE};
  background-color: ${grey[90]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${spacing[16]};
`;

export default MobileNav;
