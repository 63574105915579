import { Body, Flex, Icon, grey, spacing, white } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import type { ProductDetailsCardProps } from '../types';

type ProductExploreButtonProps = {
  direction: 'horizontal' | 'vertical';
  productCard: ProductDetailsCardProps;
  isHovered: boolean;
};

export const ProductExploreButton: React.FC<ProductExploreButtonProps> = ({
  direction,
  productCard,
  isHovered,
}) => {
  return (
    <Flex
      flexDirection={direction === 'horizontal' ? 'row' : undefined}
      gap={direction === 'horizontal' ? spacing[16] : undefined}
      justifyContent={direction === 'horizontal' ? undefined : 'space-between'}
      data-test-id="button-container"
    >
      {productCard.primaryCTA.text && (
        <Body
          size="extraSmall"
          textColor="white"
          style={{ fontWeight: 600 }}
          data-test-id="primary-cta-text"
        >
          {productCard.primaryCTA.text}
        </Body>
      )}
      <ChevronButton
        data-test-id="chevron-button"
        aria-label="explore-button"
        isHovered={isHovered}
        onClick={() => (window.location.href = productCard.primaryCTA.link.url)}
      >
        <Icon name="carouselArrow" height={24} primaryColor={white} rotate={180} />
      </ChevronButton>
    </Flex>
  );
};

const ChevronButton = styled.button<{
  isHovered: boolean;
}>`
  margin: 0 !important;
  border: none;
  background: none;
  border-radius: 50%;
  height: ${spacing[24]} !important;
  padding: 0;
  transition: all 300ms ease-in-out;

  svg {
    opacity: 0.8;
  }

  ${props =>
    props.isHovered &&
    `
    {
      cursor: pointer;
      background: ${grey[30]};

      path {
        fill: ${grey[90]};
        transition: all 0.15s ease-in-out 0s;
      }
    }
  `}
`;
