import { Body, Flex, Icon, grey, spacing, white } from '@pelotoncycle/design-system';
import { toVariationCopy } from '@pelotoncycle/page-builder';
import React from 'react';
import styled from 'styled-components';
import type { ProductDetailsCardData } from '@ecomm/copy/helpers/types';
import { useCtaCopyTestToggle } from '@ecomm/feature-toggle/CtaCopyTest';
import { ProductCardDirection } from './types';

type ProductExploreButtoProps = {
  direction: ProductCardDirection;
  productCard: ProductDetailsCardData;
  isHovered: boolean;
};

export const ProductExploreButton: React.FC<
  React.PropsWithChildren<ProductExploreButtoProps>
> = ({ direction, productCard, isHovered }) => {
  const { isExperimentActive, variables, activeVariation } = useCtaCopyTestToggle();
  const text = isExperimentActive
    ? toVariationCopy({
        originalCopy: productCard.primaryCta?.text,
        ctaLink: productCard.primaryCta?.url,
        activeVariation,
        variables,
      })
    : productCard.primaryCta?.text;

  return (
    <Flex
      flexDirection={direction === ProductCardDirection.Horizontal ? 'row' : undefined}
      gap={direction === ProductCardDirection.Horizontal ? spacing[16] : undefined}
      justifyContent={
        direction === ProductCardDirection.Horizontal ? undefined : 'space-between'
      }
    >
      {productCard.primaryCta?.text && (
        <Body size="extraSmall" textColor="white" style={{ fontWeight: 600 }}>
          {text}
        </Body>
      )}
      <ChevronButton aria-label="explore-button" isHovered={isHovered}>
        <Icon name="carouselArrow" height={24} primaryColor={white} rotate={180} />
      </ChevronButton>
    </Flex>
  );
};

const ChevronButton = styled.button<{
  isHovered: boolean;
}>`
  margin: 0 !important;
  border-radius: 50%;
  height: ${spacing[24]} !important;
  transition: all 300ms ease-in-out;

  svg {
    opacity: 0.8;
  }

  ${props =>
    props.isHovered &&
    `
    {
      cursor: pointer;
      background: ${grey[30]};

      path {
        fill: ${grey[90]};
        transition: all 0.15s ease-in-out 0s;
      }
    }
  `}
`;
