import fetch from 'node-fetch';

const fetchWWWBuilderData = async <T extends any = any>(functionPath: string) => {
  const res = await fetch(`https://www.onepeloton.com${functionPath}`);

  if (!res.ok) {
    console.error(res);
    throw new Error('Error response from builder');
  }

  return (await res.json()) as T;
};

export default fetchWWWBuilderData;
