import { grey, spacing, Container } from '@pelotoncycle/design-system';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import { checkAndRemoveLocalePrefix } from '@peloton/internationalize/models/path';
import { useOnClickOutside } from '@peloton/react';
import { media } from '@peloton/styles';
import { GlobalReferenceContext } from '@acme-ui/global/GlobalReferenceProvider';
import { zIndex } from '@ecomm/header/constants';
import { gradientFromScrollPosition, scrollRatio } from '@ecomm/header/utils';
import useScrollPosition from '@ecomm/hooks/useScrollPosition';
import { NavContext } from './NavProvider';
import {
  NAV_HEIGHT_MOBILE,
  NAV_HEIGHT_TABLET_XLARGE,
  NAV_HEIGHT_DESKTOP_LARGE,
} from './styles';

export const NavContainer: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { subHeader } = useContext(GlobalReferenceContext);
  const { setSelectedNavItem } = useContext(NavContext);
  const { position: currentPosition, setPosition } = useScrollPosition();
  const navContainerRef = useRef<HTMLDivElement>(null);
  const shouldStick = !subHeader;

  const isHomePage =
    checkAndRemoveLocalePrefix(
      typeof window === 'undefined' ? '' : window.location.pathname,
    ) === '/';

  const transparentNavStyle = useMemo(() => {
    const backgroundStyles = isHomePage
      ? { background: gradientFromScrollPosition(currentPosition) }
      : {};
    return {
      ...backgroundStyles,
    };
  }, [currentPosition, isHomePage]);

  useOnClickOutside(navContainerRef, () => {
    setSelectedNavItem(null);
  });

  useEffect(() => {
    const initialLoadPosition = scrollRatio(window.pageYOffset);
    setPosition(initialLoadPosition);
  }, [setPosition]);

  return (
    <StyledNavContainer
      ref={navContainerRef}
      as="nav"
      data-test-id="globalHeader"
      style={transparentNavStyle}
      isHomepage={isHomePage}
      sticky={shouldStick}
    >
      {children}
    </StyledNavContainer>
  );
};

const transparencyStyles = css`
  + * {
    margin-top: -${spacing[48]};

    ${media.tabletXLarge`
    margin-top: -${spacing[64]};
  `}

    ${media.desktopLarge`
      margin-top: -${spacing[72]};
  `}
  }
`;

const StyledNavContainer = styled(Container)<{ isHomepage: boolean; sticky: boolean }>`
  position: ${props => (props.sticky ? 'sticky' : 'relative')};
  top: 0;
  height: ${NAV_HEIGHT_MOBILE};
  background-color: ${grey[90]};
  display: flex;
  align-items: center;
  padding: 0 ${spacing[16]};
  gap: 100px;
  z-index: ${zIndex.HEADER};

  ${props => props.isHomepage && transparencyStyles}

  ${media.tabletXLarge`
    height: ${NAV_HEIGHT_TABLET_XLARGE};
    padding: 0 ${spacing[24]};
  `}

  ${media.desktopLarge`
    height: ${NAV_HEIGHT_DESKTOP_LARGE};
  `}
`;

export default NavContainer;
