import { Support, grey } from '@pelotoncycle/design-system';
import React from 'react';
import CartContent from '@ecomm/cart-next/shared/CartContent';
import AffirmCopyWithLogo from '@ecomm/financing/affirm/AffirmCopyWithLogo';
import { roundDollarsByFinancingPartner } from '@ecomm/financing/models/monthlyPaymentWithApr';
import { Partners } from '@ecomm/financing/models/Partners';
import type { Term } from '@ecomm/financing/models/term';
import ZipLogoModule from '@ecomm/financing/zip/ZipLogoModule';
import type { BundleType } from '@ecomm/shop/models/BundleType';

type FinancingPartnersSupportProps = {
  financingPartner: Partners;
  bundleType: BundleType;
  total: number;
  isVariation?: boolean;
  term: Term;
};

interface FinancingSupportContainerProps extends FinancingPartnersSupportProps {
  monthlyTotal: number;
  term: Term;
  secondaryFinancingPartner: Partners;
}

const getFinancingLink = ({
  financingPartner,
  bundleType,
  total,
  term,
}: FinancingPartnersSupportProps) => {
  switch (financingPartner) {
    case Partners.Affirm:
      return (
        <AffirmCopyWithLogo
          term={term}
          pageType="payment"
          price={total}
          bundleType={bundleType}
        />
      );
    case Partners.Zip:
      return <ZipLogoModule bundleType={bundleType} price={total} />;
    default:
      return;
  }
};

const FinancingSupportContainer: React.FC<
  React.PropsWithChildren<FinancingSupportContainerProps>
> = ({
  monthlyTotal,
  term,
  total,
  financingPartner,
  secondaryFinancingPartner,
  bundleType,
}) => (
  <Support size="large" textColor={grey[70]}>
    <CartContent
      field="financingPricing"
      values={{
        monthlyTotal: roundDollarsByFinancingPartner(monthlyTotal, financingPartner),
        term,
      }}
    />
    {getFinancingLink({
      financingPartner,
      bundleType,
      total,
      term,
    })}
    {secondaryFinancingPartner &&
      getFinancingLink({
        financingPartner: secondaryFinancingPartner,
        bundleType,
        total,
        term,
      })}
  </Support>
);

export default FinancingSupportContainer;
