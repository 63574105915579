import { Support } from '@pelotoncycle/design-system';
import React from 'react';
import { useLocale } from '@peloton/internationalize';
import { useRentalPrices } from '@ecomm/rentals/useRentalPrices';
import useCartWithVariables from '../hooks/useCartWithVariables';
import CartContent from '../shared/CartContent';

type Props = { className?: string };

const LeasingDisclaimer: React.FC<React.PropsWithChildren<Props>> = ({ className }) => {
  const locale = useLocale();
  const cart = useCartWithVariables().data?.cart;
  const hasEquipmentLease = cart?.hasEquipmentLease;
  const hasBikePlusLease = cart?.hasBikePlusLease;
  const BIKE_RENTAL_PRICE = useRentalPrices('BIKE', locale);
  const BIKE_PLUS_RENTAL_PRICE = useRentalPrices('BIKE_PLUS', locale);
  const monthlyPrice = hasBikePlusLease
    ? BIKE_PLUS_RENTAL_PRICE.monthly
    : BIKE_RENTAL_PRICE.monthly;

  if (!hasEquipmentLease) {
    return null;
  }

  return (
    <Support size="small" className={className}>
      <CartContent
        field={hasBikePlusLease ? 'bikePlusLeaseMonthlyCharge' : 'bikeLeaseMonthlyCharge'}
        values={{
          monthlyPrice,
        }}
      />
    </Support>
  );
};

export default LeasingDisclaimer;
