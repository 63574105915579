import { Eyebrow } from '@pelotoncycle/design-system';
import React from 'react';
import type { FC } from 'react';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { Link } from '@ecomm/internationalize-ui';
import { useLoginUrl, useNextLoginUrl } from '@ecomm/oauth/OAuthProvider';

type SignInLinkProps = {
  text: string;
  currentHref: string;
};
export const SignInLink: FC<SignInLinkProps> = ({ text, currentHref }) => {
  const legacyLoginUrl = useLoginUrl()(currentHref);
  const nextLoginUrl = useNextLoginUrl()(currentHref);
  const isToggleActive = useIsToggleActive();
  const isNextAuthLoginActive = isToggleActive('nextAuthLogin');
  const loginUrl = isNextAuthLoginActive ? nextLoginUrl : legacyLoginUrl;

  const clickHandler = () => {
    const uuid = window.crypto.randomUUID();
    sessionStorage.setItem('login-uuid', uuid);
  };

  return (
    <Link to={loginUrl} href={loginUrl} hasUnderline={false} onClick={clickHandler}>
      <Eyebrow size="medium">{text}</Eyebrow>
    </Link>
  );
};
