import { Container, Media, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import { useMedia } from 'react-use';
import { BreakpointWidth } from '@peloton/styles/breakpoints';
import type { ProductImageProps } from '../types';

const PRODUCT_IMAGE_DIMENSIONS = {
  mobile: spacing[120],
  desktop: '178px',
};

export const ProductImage: React.FC<ProductImageProps> = ({ image }) => {
  const isMobileOrTablet = useMedia(`(max-width: ${BreakpointWidth.desktopLarge}px)`);
  return (
    <Container
      height={PRODUCT_IMAGE_DIMENSIONS}
      width={PRODUCT_IMAGE_DIMENSIONS}
      data-test-id="product-image"
    >
      <Media
        data-test-id="product-image-media"
        media={{
          alt: 'Product Card Image',
          mobile: image.mobile,
          tablet: image.mobile,
          desktop: image.mobile,
          type: 'image',
        }}
        width={isMobileOrTablet ? '120px' : '178px'}
      />
    </Container>
  );
};
