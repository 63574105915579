import type { Locale } from 'packages/@peloton/internationalize/models/locale';
import { toCountryFromLocale } from '@peloton/internationalize';
import { toCommercetoolsClient } from '@ecomm/commercetools/apollo';
import { SearchProductByKeyDocument } from '@ecomm/commercetools/queries/SearchProductByKey.generated';
import type { ProductSelection } from '@ecomm/graphql/types.generated';

const mergeVariants = (productData: any) => {
  if (!productData || !productData.masterVariant) {
    return [];
  }
  return [productData.masterVariant, ...(productData.variants || [])];
};

export const getCtVariantBySelection = async (
  locale: Locale,
  currency: string,
  productSelections: ProductSelection[],
): Promise<any[]> => {
  const ctClient = toCommercetoolsClient();
  const country = toCountryFromLocale(locale);
  const matchingVariants: any[] = [];

  for (const productSelection of productSelections) {
    const productKey = productSelection.product;

    const result = await ctClient.query({
      query: SearchProductByKeyDocument,
      variables: {
        where: `key="${productKey}"`,
        acceptLanguage: [locale],
        currency,
        country,
      },
      fetchPolicy: 'cache-first',
    });

    const productVariants = mergeVariants(
      result.data?.products?.results?.[0]?.masterData.current,
    );

    for (const selection of productSelection.selections) {
      const expectedSizeLabel = selection.attribute.toLowerCase();
      const expectedSubscriptionSize = String(selection.option);

      const matchingVariant = productVariants.find(variant => {
        const attributes = variant.attributesRaw || [];

        const sizeLabelMatch = attributes.find(
          (attr: { name: string; value: any }) =>
            attr.name === 'size-label' &&
            Object.values(attr.value).some(
              (label: string) => String(label).toLowerCase() === expectedSizeLabel,
            ),
        );

        const subscriptionSizeMatch = attributes.find(
          (attr: { name: string; value: any }) =>
            attr.name === 'subscription-size' &&
            String(attr.value) === expectedSubscriptionSize,
        );

        return sizeLabelMatch && subscriptionSizeMatch;
      });

      if (matchingVariant) {
        const legacyOptionId = matchingVariant.attributesRaw.find(
          (attr: { name: string }) => attr.name === 'legacy-option-id',
        )?.value;

        const name = matchingVariant.attributesRaw.find(
          (attr: { name: string }) => attr.name === 'variant-name',
        )?.value[locale];

        const price = matchingVariant.price
          ? { amount: matchingVariant.price.value.centAmount }
          : { amount: 0 };

        matchingVariants.push({
          id: legacyOptionId,
          slug: matchingVariant.key,
          skus: [matchingVariant.sku],
          name,
          price,
        });
      }
    }
  }

  return matchingVariants;
};
