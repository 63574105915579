import type { ProductDetailsData } from '@ecomm/copy/helpers/types';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { useProductStates } from '@ecomm/product-states/Context';
import { Product } from '@ecomm/product-states/models/product';
import { ProductStates } from '@ecomm/product-states/models/productState';

type KeyAndToggleMap = Record<string, Filter>;

type Filter = boolean;

const useFilteredProductCards = (
  products: ProductDetailsData[],
): ProductDetailsData[] => {
  const {
    isProductAvailableForPurchaseOrSoldOut,
    isProductAvailableForMarketingPages,
    verifyProductState,
  } = useProductStates();
  const isShowGiftCardMenu = useIsToggleActive()('showGiftCardMenu');
  const isGuideRemovedFromMainNav = useIsToggleActive()('removeGuideFromMainNav');
  const isShowStrengthPlus = useIsToggleActive()('showStrengthPlusInNav');

  const keyAndProductStateMap: KeyAndToggleMap = {
    'nav.productCard.treadAccessories':
      isProductAvailableForPurchaseOrSoldOut(Product.Tread) ||
      isProductAvailableForPurchaseOrSoldOut(Product.TreadPlus),
    'nav.productCard.bikeAccessories':
      isProductAvailableForPurchaseOrSoldOut(Product.Bike) ||
      isProductAvailableForPurchaseOrSoldOut(Product.BikePlus),
    'nav.productCard.tread': isProductAvailableForMarketingPages(Product.Tread),
    'nav.productCard.treadPlus': isProductAvailableForMarketingPages(Product.TreadPlus),
    'nav.productCard.bike': isProductAvailableForMarketingPages(Product.Bike),
    'nav.productCard.bikePlus': isProductAvailableForMarketingPages(Product.BikePlus),
    'nav.productCard.strength': isProductAvailableForMarketingPages(
      Product.RainforestCafe,
    ),
    'nav.productCard.bikeRefurbished':
      isProductAvailableForPurchaseOrSoldOut(Product.RefurbBikePlus) &&
      isProductAvailableForPurchaseOrSoldOut(Product.RefurbBike),
    'nav.productCard.bikePlusRefurbished': isProductAvailableForPurchaseOrSoldOut(
      Product.RefurbBikePlus,
    ),
    'nav.productCard.GiftCard': isShowGiftCardMenu,
    'nav.productCard.Guide':
      isProductAvailableForPurchaseOrSoldOut(Product.RainforestCafe) &&
      isGuideRemovedFromMainNav,
    'nav.productCard.strengthPlus': isShowStrengthPlus,
  };

  const filteredProducts = products.filter(({ key }) => {
    const filterObject = keyAndProductStateMap;
    const hasFilter = Object.keys(filterObject).includes(key);
    return (hasFilter && filterObject[key]) || !hasFilter;
  });

  const isUnavailableOrLeadCapture = (product: Product): boolean =>
    verifyProductState(product, [
      ProductStates.Unavailable,
      ProductStates.UnavailableMarketing,
      ProductStates.UnavailableMarketingLeadCapture,
    ]);

  const filterSecondaryLinks = filteredProducts.map(({ secondaryCta, ...rest }) => {
    const secondaryCtaKey = secondaryCta?.key;
    let shouldFilterCta = false;

    const checkState = (key: string, product: Product): boolean =>
      secondaryCtaKey === key && isUnavailableOrLeadCapture(product);

    if (
      checkState('nav.productCard.bikePlus.shop', Product.BikePlus) ||
      checkState('nav.productCard.bike.shop', Product.Bike) ||
      checkState('nav.productCard.tread.shop', Product.Tread) ||
      checkState('nav.productCard.treadPlus.shop', Product.TreadPlus)
    ) {
      shouldFilterCta = true;
    }

    return {
      ...rest,
      ...(shouldFilterCta ? {} : { secondaryCta }),
    };
  });

  return filterSecondaryLinks;
};

export default useFilteredProductCards;
