import { white } from '@pelotoncycle/design-system';
import React from 'react';
import useGetCartItems from '@ecomm/commercetools/hooks/useGetCartItems';
import { ItemCountBadge } from './ItemCountBadge';

export const CartIconBuyFlow: React.FC<
  React.PropsWithChildren<{ hideCount: boolean }>
> = ({ hideCount }) => {
  const { totalLineItemQuantity } = useGetCartItems();

  return (
    <>
      {!hideCount && totalLineItemQuantity ? (
        <ItemCountBadge size="small" textColor={white}>
          {totalLineItemQuantity}
        </ItemCountBadge>
      ) : null}
    </>
  );
};
