import { getCountryCart } from '@ecomm/checkout-commercetools/helpers/ct-cart-helper';
import { ShopCartUpdateActionType } from '@ecomm/graphql/types.generated';
import { useUpdateShopCartJupiterMutation } from '@ecomm/shop-cart/graphql/mutations/UpdateCart.generated';

/**
 * Hook to recalculate the cart.
 * @returns An object containing the `recalculate` function.
 */
export const useRecalculateCart = () => {
  const [updateCartMutation] = useUpdateShopCartJupiterMutation({});

  /**
   * Recalculates the cart by triggering the appropriate mutation.
   */
  const recalculate = async () => {
    return updateCartMutation({
      variables: {
        input: {
          country: getCountryCart(),
          actions: [
            {
              recalculate: {
                updateProductData: true,
              },
              actionType: ShopCartUpdateActionType.Recalculate,
            },
          ],
        },
      },
    });
  };

  return {
    recalculate,
  };
};
