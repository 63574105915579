import { black } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import type { PropsWithChildren, RefObject } from 'react';
import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { BIKES_NAV_KEY, TREAD_NAV_KEY, TREADS_NAV_KEY } from '@acme-ui/nav/constants';
import type {
  ProductDetailsCardData,
  ProductDetailsData,
  ProductNavData,
} from '@ecomm/copy/helpers/types';
import useFilteredProductCards from '../../hooks/useFilteredProductCards';
import { useIsUserInUnitedStates } from '../../hooks/useIsUserInUnitedStates';
import ProductCard from './ProductCard';
import SecondaryLinks from './SecondaryLinks';
import SingleProductCtaSection from './SingleProductCtaSection';

type MegaMenuProps = {
  wrapperRef?: RefObject<HTMLDivElement>;
  label: string;
  data: Pick<ProductNavData, 'key' | 'products' | 'secondaryLinks'>;
  realignLastChild: boolean;
  className?: string;
};

const MegaMenu: React.FC<React.PropsWithChildren<PropsWithChildren<MegaMenuProps>>> = ({
  wrapperRef,
  label,
  data: { key, products, secondaryLinks },
  realignLastChild,
  className = '',
}) => {
  const filteredProductCards: ProductDetailsData[] = useFilteredProductCards(products);
  const isUSLocale = useIsUserInUnitedStates();

  const showSingleProductCtaSection =
    filteredProductCards.length === 1 && !secondaryLinks;

  const shouldShowSecondaryLinks = Boolean(secondaryLinks) && !isUSLocale;

  return (
    <StyledMegaMenu
      data-test-id={key}
      data-element-id={key}
      ref={wrapperRef}
      aria-label={label}
      hasSecondaryLinks={shouldShowSecondaryLinks}
      realignLastChild={realignLastChild}
      className={className}
      hasAccessoriesOffset={key === 'nav.accessories'}
      // Add an offset for both Bikes and Tread, to get the box to line up with the arrow.
      hasBikesOffset={
        (key === BIKES_NAV_KEY || key === TREAD_NAV_KEY || key === TREADS_NAV_KEY) &&
        isUSLocale
      }
    >
      <FlexWrapper>
        <CardContainer hasSecondaryLinks={shouldShowSecondaryLinks}>
          {filteredProductCards.length > 0 ? (
            <>
              {filteredProductCards.map(productData => (
                <ProductCard
                  key={productData.productName}
                  categoryId={key}
                  data={productData as ProductDetailsCardData}
                  showCtas={shouldShowSecondaryLinks || isUSLocale}
                  hasSecondaryLinks={shouldShowSecondaryLinks}
                />
              ))}
            </>
          ) : null}
        </CardContainer>
        {showSingleProductCtaSection && (
          <SingleProductCtaSection categoryId={key} products={products} />
        )}
      </FlexWrapper>
      {shouldShowSecondaryLinks && (
        <SecondaryLinks categoryId={key} secondaryLinks={secondaryLinks} />
      )}
    </StyledMegaMenu>
  );
};

export default MegaMenu;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const StyledMegaMenu = styled.div<{
  hasSecondaryLinks: boolean;
  realignLastChild: boolean;
  hasAccessoriesOffset?: boolean;
  hasBikesOffset?: boolean;
}>`
  color: ${black};
  position: absolute;
  top: 58px;
  left: 50%;
  transform: translate(
    ${({ hasAccessoriesOffset, hasBikesOffset }) =>
      hasAccessoriesOffset ? '-9rem' : hasBikesOffset ? '-25rem' : '-50%'},
    0
  );
  display: flex;
  justify-content: space-between;
  border-radius: 3px;
  box-shadow: 0 30px 80px 0 ${rgba(black, 0.37)};
  background-color: #ffffff;
  min-width: ${({ hasSecondaryLinks }) => (hasSecondaryLinks ? '50rem' : '27.875rem')};
  animation: ${fadeIn} 600ms;
  ${({ realignLastChild }) =>
    realignLastChild
      ? css`
          &:last-child {
            right: 0;
            left: unset;
            transform: none;
          }
        `
      : ''};
`;

const FlexWrapper = styled.span`
  display: flex;
  flex-direction: column;
`;

const CardContainer = styled.div<{ hasSecondaryLinks: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: ${({ hasSecondaryLinks }) =>
    hasSecondaryLinks ? '3.5rem 4.5rem 2rem 4rem' : '3rem 4rem 3rem 4rem'};
`;
