import { useCallback } from 'react';
import { useTracking } from 'react-tracking';
import {
  toCTCategory,
  toCTSegmentProducts,
} from '@ecomm/cart-next/analytics/segmentMappings';
import {
  GUIDE,
  getPrepaidAAMProduct,
} from '@ecomm/checkout-commercetools/helpers/ct-cart-helper';
import logAction from '@ecomm/logging/logAction';
import type { Option } from '@ecomm/pg-checkout-commercetools/components/cart-panel/GiftingMembership';
import type { CtCartFragment } from '@ecomm/shop-cart/graphql/fragments.generated';
import {
  hasCpo,
  getBundleNames,
  hasAccessoryBundle,
  getAccessoryNames,
  hasOpc,
  hasAccessory,
} from './analytics-helper';

const toEvent = (prepaidAamOption: Option, shopCartData?: CtCartFragment | null) => {
  const items = shopCartData?.lineItems ?? [];
  const category = toCTCategory(items);
  const hasGuide = Boolean(category.includes(GUIDE));

  let bundleName = getBundleNames(items);

  if (hasGuide) bundleName = [...(bundleName || ''), 'Guide'];

  const subscriptionDuration =
    prepaidAamOption.configurations.find(
      configuration => configuration.attribute === 'subscription-size',
    )?.option ?? '';

  const name =
    prepaidAamOption.configurations.find(
      configuration => configuration.attribute === 'variant-name',
    )?.option ?? '';

  return {
    event: 'Gift Subscription Selected',
    properties: {
      cartId: shopCartData?.id,
      category: 'Peloton All Access Membership',
      hasTradeIn: false,
      products: toCTSegmentProducts(items),
      hasAccessory: hasAccessory(items),
      hasGuide,
      hasAccessoryBundle: hasAccessoryBundle(items),
      accessoryName: getAccessoryNames(items),
      hasCPO: hasCpo(items),
      hasOPC: hasOpc(items),
      position: 'cart',
      addedFromCart: true,
      quantity: 1,
      bundleName,
      hasSubscriptionGift: Boolean(getPrepaidAAMProduct(items)),
      subscriptionDuration: subscriptionDuration
        ? `${subscriptionDuration} months`
        : null,
      name,
      price: prepaidAamOption.price?.amount ?? null,
      id: prepaidAamOption.value,
      sku: prepaidAamOption.sku,
      slug: prepaidAamOption.productKey,
    },
  };
};

const useTrackGiftSubscriptionSelected = () => {
  const { trackEvent } = useTracking();

  const trackCtGiftSubscriptionSelected = useCallback(
    (prepaidSubscriptionOption: Option, cart: CtCartFragment | undefined | null) => {
      try {
        trackEvent(toEvent(prepaidSubscriptionOption, cart));
      } catch (e) {
        logAction('Error tracking gift subscription selected', e);
      }
    },
    [trackEvent],
  );

  return {
    trackCtGiftSubscriptionSelected,
  };
};
export default useTrackGiftSubscriptionSelected;
