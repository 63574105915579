import { Icon, white } from '@pelotoncycle/design-system';
import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import FocusTrap from '@peloton/accessibility/FocusTrap';
import { theUserIsInGermany } from '@peloton/internationalize';
import { useLocale } from '@peloton/next/hooks/useLocale';
import { media } from '@peloton/styles/breakpoints';
import useNav from '@content/client/www/nav/useNav';
import CartIcon from '@ecomm/cart-next/cart-icon/CartIcon';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { HeaderNavContext } from '@ecomm/header/HeaderNavProvider';
import keyboardTrap from '@ecomm/header/hooks/keyboardTrap';
import { Menu } from '@ecomm/header/models';
import type { UtilitiesMenuRefs } from '@ecomm/header/models';
import { onMenuClick } from '@ecomm/header/utils';
import { useIsUserInUnitedStates } from '../hooks/useIsUserInUnitedStates';
import { fadeInAnimation } from '../ProductsMenu/NavAnimations';
import AccountMenu from './AccountMenu';
import GeoPickerMenu from './GeoPickerMenu';
import MenuTrigger from './MenuTrigger';

type Props = {
  onHamburgerButtonClick: (
    e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>,
  ) => void;
  onCartIconClick: () => void;
  shouldRenderCart: boolean;
  isShowroomActive: boolean;
  isPanelNavOpen: boolean;
  shouldDisplaySimplifiedNav: boolean;
  shouldHideSignup: boolean;
};

const UtilitiesMenu: React.FC<React.PropsWithChildren<Props>> = ({
  onHamburgerButtonClick,
  onCartIconClick,
  shouldRenderCart,
  isShowroomActive,
  isPanelNavOpen,
  shouldDisplaySimplifiedNav,
  shouldHideSignup,
}) => {
  const {
    currentMenu,
    setCurrentMenu,
    focusedMenu,
    setFocusedMenu,
    hideMenu,
  } = useContext(HeaderNavContext);
  const locale = useLocale();
  const wrapperRef = useRef<HTMLUListElement>(null);
  const menus = [Menu.GeoPicker, Menu.Account];
  const menuItemRefs: UtilitiesMenuRefs = {
    [Menu.GeoPicker]: useRef(null),
    [Menu.Account]: useRef(null),
  };

  const { content, isLoading } = useNav(locale);

  const isUSLocale = useIsUserInUnitedStates();
  const isDE3PTransitionActive = useIsToggleActive()('de3pTransition');

  useEffect(
    keyboardTrap(
      wrapperRef,
      menus,
      currentMenu,
      focusedMenu,
      menuItemRefs,
      hideMenu,
      setFocusedMenu,
    ),
    [currentMenu, focusedMenu],
  );

  if (isLoading) {
    return null;
  }
  const { toggleLocator, toggleAccount, toggleCart } = content;
  const shouldDisplayLocationMenu =
    toggleLocator &&
    isShowroomActive &&
    !shouldDisplaySimplifiedNav &&
    !isDE3PTransitionActive &&
    !isUSLocale;

  const shouldDisplayCartMenu =
    !shouldHideSignup && toggleCart && shouldRenderCart && !isDE3PTransitionActive;

  return (
    <StyledFocusTrap disabled={!menus.includes(currentMenu)} className="focus-lock">
      <UtilitiesMenuContainer ref={wrapperRef}>
        {(shouldDisplayLocationMenu || theUserIsInGermany(locale)) && (
          <StyledMenuTrigger
            icon={<Icon name="location" primaryColor={white} />}
            label="Location menu"
            dataTestId="location"
            buttonRef={menuItemRefs[Menu.GeoPicker]}
            isOpen={currentMenu === Menu.GeoPicker}
            onClick={onMenuClick({
              currentMenu,
              menuItem: Menu.GeoPicker,
              setCurrentMenu,
              setFocusedMenu,
            })}
          >
            <GeoPickerMenu />
          </StyledMenuTrigger>
        )}
        {!shouldDisplaySimplifiedNav && !shouldHideSignup && toggleAccount && (
          <MenuTrigger
            icon={<Icon name="account" primaryColor={white} />}
            dataTestId="account"
            label="Account menu"
            buttonRef={menuItemRefs[Menu.Account]}
            isOpen={currentMenu === Menu.Account}
            onClick={onMenuClick({
              currentMenu,
              menuItem: Menu.Account,
              setCurrentMenu,
              setFocusedMenu,
            })}
          >
            <AccountMenu />
          </MenuTrigger>
        )}
        {shouldDisplayCartMenu && (
          <li>
            <button
              aria-label={content?.cartButtonLabel?.value}
              data-test-id="cart"
              onClick={() => {
                onCartIconClick();
                setCurrentMenu(Menu.None);
              }}
            >
              <CartIcon />
            </button>
          </li>
        )}
        {!shouldDisplaySimplifiedNav && !shouldHideSignup && (
          <HamburgerMenu shouldShowOnDesktop={isUSLocale}>
            <button
              aria-expanded="false"
              aria-label={content?.hamburgerButtonLabel?.value}
              data-test-id="panelNavIcon"
              onClick={e => {
                onHamburgerButtonClick(e);
                setCurrentMenu(Menu.None);
              }}
            >
              <Icon name={isPanelNavOpen ? 'close' : 'mobileMenu'} primaryColor={white} />
            </button>
          </HamburgerMenu>
        )}
      </UtilitiesMenuContainer>
    </StyledFocusTrap>
  );
};

export default UtilitiesMenu;

export const ICON_SIDE_PADDING = '12px';

const StyledFocusTrap = styled(FocusTrap)`
  flex: 1;
  justify-content: flex-end;
`;

const StyledMenuTrigger = styled(MenuTrigger)`
  display: none;

  ${media.desktopLarge`
    display: list-item;
  `}
`;

const HamburgerMenu = styled.li<{ shouldShowOnDesktop: boolean }>`
  ${media.desktopLarge<{ shouldShowOnDesktop: boolean }>`
    display: ${({ shouldShowOnDesktop }) => (shouldShowOnDesktop ? '' : 'none')};
  `}
`;

export const UtilitiesMenuContainer = styled.ul`
  ${fadeInAnimation};
  position: relative;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  height: 100%;

  > li {
    height: 100%;
    ${media.desktopLarge`
      &:not(:first-child) {
        margin-left: .75rem;
      }
    `}
    ${media.desktopXLarge`
      &:not(:first-child) {
        margin-left: 1.5rem;
      }
    `}
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: calc(2rem + 2 * ${ICON_SIDE_PADDING});
    cursor: pointer;

    ${media.desktopLarge`
      width: 2rem;
    `}

    svg {
      height: 1.75rem;
      pointer-events: none;
      width: 1.75rem;
      fill: none;

      ${media.tabletXLarge`
        height: 2rem;
        width: 2rem;
      `}
    }
  }
`;
