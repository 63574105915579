import {
  Eyebrow,
  spacing,
  Flex,
  black,
  grey,
  Container,
} from '@pelotoncycle/design-system';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useClient } from '@peloton/api/ClientContext';
import { useLocale } from '@peloton/internationalize';
import { addCurrentLocaleToUrl } from '@peloton/internationalize/models/path';
import { media } from '@peloton/styles';
import useCartContent from '@content/client/www/cart/useCart';
import {
  useIsCartLoading,
  useSetIsCartLoading,
  useHasItemJustBeenAdded,
} from '@ecomm/cart-next/context/CartContext';
import { useAddPromoCodeMutation } from '@ecomm/cart-next/graphql/mutations/AddPromoCode.generated';
import { useSetIsGiftMutation } from '@ecomm/checkout/hooks/setIsGiftOnCart.generated';
import { toCommercetoolsClient } from '@ecomm/commercetools/apollo';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';

import logAction from '@ecomm/logging/logAction';
import { NEXT_CHECKOUT_ROUTE } from '@ecomm/pg-checkout-commercetools/routes/checkoutV2Route';
import {
  useRemoveItemFromCart,
  RemoveType,
  checkIfAllProductsAreAvailable,
  useRecalculateCart,
  useMonolithCartRefetch,
} from '@ecomm/pg-checkout-commercetools/utils/cartUtils';
import { convertCurrentCTCartToMonolith } from '@ecomm/pg-checkout-commercetools/utils/convertCurrentCTCartToMonolith';
import { useMigrationStatus } from '@ecomm/pg-checkout-commercetools/utils/useMigrationStatus';
import { useUpdateShopCartJupiterMutation } from '@ecomm/shop-cart/graphql/mutations/UpdateCart.generated';
import { getCartId } from '../../state/selectors';
import CartPanelOverlay from './CartPanelOverlay';
import CartSummaryView from './CartSummaryView';
import EmptyCartView from './EmptyCartView';

export const handleRemoveItemFunction = (
  isBundle: boolean,
  id: string,
  removeLineItemFromCT: (id: string) => Promise<void>,
  removeBundleFromCT: (id: string) => Promise<void>,
) => {
  if (!isBundle) {
    removeLineItemFromCT(id);
  } else {
    removeBundleFromCT(id);
  }
};

type Props = {
  className?: string;
  handlePostConversion: Function;
  isCartEmpty: boolean;
  loading: boolean;
};

export type RemoveItemArgs = {
  id: any;
  isBundle: boolean;
  isBundleCFU?: boolean;
  quantity?: number;
};

const CartSummary: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  handlePostConversion,
  isCartEmpty,
  loading,
}) => {
  const [updateCartMutation, uptResult] = useUpdateShopCartJupiterMutation();
  const setIsCartLoading = useSetIsCartLoading();
  const { content: cartContent } = useCartContent();
  const isCartLoading = useIsCartLoading();
  const { data, refetchMonolithCart } = useMonolithCartRefetch();
  const restClient = useClient();
  const ctClient = toCommercetoolsClient();
  const { removeItemFromCart, result: removeResult } = useRemoveItemFromCart();
  const { recalculate } = useRecalculateCart();
  const [ctCartLoading, setCtCartLoading] = useState(false);
  const [setIsGiftMutation] = useSetIsGiftMutation();
  const [addPromoCodeMutation] = useAddPromoCodeMutation();
  const hasBeenAdded = useHasItemJustBeenAdded();
  const hasItemsInMonolithCart = Boolean(data?.cart?.items.length);
  const isToggleActive = useIsToggleActive();
  const isProjectPhoenixEnabled = isToggleActive('projectPhoenix');
  const cartIsConverting = !hasItemsInMonolithCart && hasBeenAdded;
  const { buyFlowCheckoutEnabled } = useMigrationStatus();

  const locale = useLocale();
  const nextCheckoutUrlWithLocale = addCurrentLocaleToUrl(
    `/${NEXT_CHECKOUT_ROUTE}`,
    locale,
  );

  const showOverLay =
    (isCartLoading ||
      uptResult.loading ||
      removeResult.loading ||
      loading ||
      ctCartLoading) &&
    !cartIsConverting;

  const removeAllItemQuantity = ({
    id,
    isBundle,
    isBundleCFU,
    quantity,
  }: RemoveItemArgs) => {
    removeItemFromCart({
      id,
      type: isBundle ? RemoveType.BUNDLE : RemoveType.SINGLE,
      isBundleCFU: Boolean(isBundleCFU),
      quantity,
    });
  };

  const handleCheckout = async () => {
    setCtCartLoading(true); // handle edge case of multi cart checkout
    //fetch monolith call
    const { data: monolithCartData } = await refetchMonolithCart();
    setIsCartLoading(true); // loading is set after refetch - so that the ct cart converts back to monolith cart
    setCtCartLoading(false); // handle edge case of multi cart checkout
    if (!!monolithCartData?.cart?.numberOfItems) {
      handlePostConversion();
      return;
    }
    let updatedShopCartData = null;
    try {
      const recalculateData = await recalculate();
      updatedShopCartData = recalculateData?.data?.updateCart;
    } catch (e) {
      console.error(e);
      if (buyFlowCheckoutEnabled) {
        logAction('CT CHECKOUT ERROR', {
          cartID: getCartId(updatedShopCartData!),
          error: `Unable to start checkout due to cart refetch failure: ${e}`,
        });
      }
    }
    const allProductsAvailable = checkIfAllProductsAreAvailable(updatedShopCartData);
    if (allProductsAvailable) {
      if (buyFlowCheckoutEnabled) {
        logAction('CT CHECKOUT STARTED', {
          cartID: getCartId(updatedShopCartData!),
        });
        window.location.href = nextCheckoutUrlWithLocale;
        setIsCartLoading(false);
        setCtCartLoading(false);
        return;
      }
      await convertCurrentCTCartToMonolith(
        updatedShopCartData,
        updateCartMutation,
        setIsGiftMutation,
        addPromoCodeMutation,
        restClient,
        ctClient,
        isProjectPhoenixEnabled,
      );
      handlePostConversion();
    } else {
      setIsCartLoading(false);
      logAction('not all products are available', { shopCartData: updatedShopCartData });
    }
  };

  return (
    <StyledCartContainer>
      <StyledFlex
        position="relative"
        flexDirection="column"
        className={className}
        data-test-id="cartContainer"
      >
        <CartTitle size="large" data-test-id="cartTitle" textColor={black}>
          {cartContent?.ariaLabel?.value}
        </CartTitle>
        {isCartEmpty ? (
          <EmptyCartView showLoader={cartIsConverting} />
        ) : (
          <CartSummaryView
            data-test-id="orderSummary"
            {...(buyFlowCheckoutEnabled
              ? { 'data-dd-action-name': 'CT CHECKOUT - Checkout Button' }
              : {})}
            handleCheckout={handleCheckout}
            cartLoading={showOverLay}
            removeItem={removeAllItemQuantity}
          />
        )}
      </StyledFlex>
      {showOverLay && <CartPanelOverlay />}
    </StyledCartContainer>
  );
};

export default CartSummary;

const CartTitle = styled(Eyebrow)`
  margin: ${spacing[40]} ${spacing[16]} ${spacing[16]};
  ${media.tabletXLarge`
  margin: ${spacing[40]} ${spacing[24]} ${spacing[16]};
  `}
`;

const StyledFlex = styled(Flex)`
  min-height: 100vh;
  overflow: unset !important;
`;

const StyledCartContainer = styled(Container)`
  min-height: 100vh;
  width: 100vw;
  max-width: 456px;
  display: block;
  position: relative;
  background-color: ${grey[30]};
`;
