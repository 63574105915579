import { complement, isNil } from 'ramda';
import { useEffect, useState } from 'react';
import { useTrackWithDriftAndSegment } from '@peloton/drift/utils';
import type { ProductVariant } from '@ecomm/graphql/types.generated';
import { toDollars } from '@ecomm/models';
import type { ProductSelection } from '@ecomm/shop/models';
import useDynamicVariantsBySelectionsQuery from './useDynamicVariantsBySelectionsQuery';

const useAddProductToCartAnalytics = (
  addedFromCart: boolean = false,
  category: string = 'accessory',
) => {
  const [cartId, setCartId] = useState<string>('');
  const [productSelection, setProductSelection] = useState<ProductSelection>();
  const toDynamicVariantsBySelectionsQuery = useDynamicVariantsBySelectionsQuery();
  const { track } = useTrackWithDriftAndSegment();

  const giftSubscriptionDuration =
    category === 'Peloton All Access Membership' &&
    productSelection?.selections[0]?.option !== null
      ? `${productSelection?.selections[0]?.option} months`
      : null;

  useEffect(() => {
    if (cartId && productSelection != undefined) {
      const productSlug = productSelection.product;
      (async () => {
        const variants = await toDynamicVariantsBySelectionsQuery([productSelection]);
        if (!hasVariants(variants)) return;
        const { skus, id: productId, name, price } = variants[0];
        track({
          event: `Added Product`,
          properties: {
            cartId,
            price: toDollars(price.amount),
            propertyType: 'Web',
            id: productId,
            name,
            slug: productSlug,
            quantity: 1,
            sku: skus.join(','),
            category,
            addedFromCart,
            giftSubscriptionDuration,
          },
        });
      })();
    }
  }, [cartId, productSelection]);

  return { setCartId, setProductSelection };
};

export default useAddProductToCartAnalytics;

const hasVariants = (variants: any): variants is ProductVariant[] =>
  notNil(variants) && (variants?.length ?? 0) > 0;

const notNil = complement(isNil);
