import { brand } from '@pelotoncycle/design-system';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useClient } from '@peloton/api/ClientContext';
import { useErrorReporter } from '@peloton/error-reporting';
import { useLocale } from '@peloton/internationalize';
import { addCurrentLocaleToUrl } from '@peloton/internationalize/models/path';
import useCart from '@content/client/www/cart/useCart';
import useGetCartItems from '@ecomm/commercetools/hooks/useGetCartItems';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import Flyout from '@ecomm/flyout';
import NewCartSummary from '@ecomm/pg-checkout-commercetools/components/cart-panel/CartSummary';
import { useMigrationStatus } from '@ecomm/pg-checkout-commercetools/utils/useMigrationStatus';
import type { CtCartFragment } from '@ecomm/shop-cart/graphql/fragments.generated';
import useCartViewedAnalytics from '../analytics/useCartViewedAnalytics';
import useCTCartViewedAnalytics from '../analytics/useCTCartViewedAnalytics';
import CartSummary from '../cart-summary/CartSummary';
import {
  useCloseCartPanel,
  useHasItemJustBeenAdded,
  useIsCartPanelOpen,
  useOpenCartPanel,
  useSetIsCartLoading,
  useSetHasItemJustBeenAdded,
} from '../context/CartContext';
import { useCartQuery } from '../graphql/queries/Cart.generated';
import useIsReferralSession from '../hooks/useIsReferralSession';
import { useReferralCodeFromStorage } from '../hooks/useReferralCode';
import { useSingleCodeFromStorage } from '../hooks/useSingleCode';

const Panel: React.FC<React.PropsWithChildren<unknown>> = () => {
  const isOpen = useIsCartPanelOpen();
  const openCartPanel = useOpenCartPanel();
  const closeCartPanel = useCloseCartPanel();
  const setIsCartLoading = useSetIsCartLoading();
  const hasItemJustBeenAddedToCart = useHasItemJustBeenAdded();
  const setHasItemJustBeenAdded = useSetHasItemJustBeenAdded();
  const { trackCartViewed } = useCartViewedAnalytics();
  const { trackCTCartViewed } = useCTCartViewedAnalytics();
  const { errorReporter } = useErrorReporter();
  const { content, isLoading: isContentLoading } = useCart();
  const isToggleActive = useIsToggleActive();
  const isProjectPhoenixEnabled = isToggleActive('projectPhoenix');
  const { CTCartEnabled } = useMigrationStatus();
  const restClient = useClient();
  const { isReferralSession } = useIsReferralSession();
  const { data, loading: isCartLoading, refetch } = useCartQuery({
    variables: { calculateEstimatedShippingPrice: isProjectPhoenixEnabled },
    suspend: false,
    notifyOnNetworkStatusChange: true,
    throwError: false,
    reportSwallowedError: errorReporter.reportError,
  });

  const hasItemsInMonolithCart = Boolean(data?.cart?.items?.length);
  const previousMonlithCartValue = React.useRef(hasItemsInMonolithCart);
  const shouldReset = !hasItemsInMonolithCart && previousMonlithCartValue.current;
  const { data: shopCartData, loading, totalLineItemQuantity } = useGetCartItems({
    fetchPolicy: 'cache-first',
  });
  const prevShopCartQty = React.useRef(0);
  const isCartEmpty = totalLineItemQuantity === 0;

  useEffect(() => {
    previousMonlithCartValue.current = hasItemsInMonolithCart;
  }, [hasItemsInMonolithCart]);

  useEffect(() => {
    if (shouldReset) {
      setHasItemJustBeenAdded(false);
    }
  }, [shouldReset]);

  useReferralCodeFromStorage(data);
  useSingleCodeFromStorage(data);

  // Legacy cart viewed tracking
  useEffect(() => {
    if (isOpen && hasItemJustBeenAddedToCart) {
      refetch().then(res => {
        trackCartViewed(res.data);
      });
    }
  }, [hasItemJustBeenAddedToCart, isOpen, trackCartViewed]);

  // CT cart viewed tracking
  useEffect(() => {
    const currentShopCartQty = shopCartData?.totalLineItemQuantity ?? 0;
    const shopCartItemAdded = currentShopCartQty > prevShopCartQty.current;
    const shouldTrackCTCartViewed =
      CTCartEnabled && isOpen && shopCartItemAdded && shopCartData;

    if (shouldTrackCTCartViewed) {
      trackCTCartViewed(shopCartData as CtCartFragment);
      prevShopCartQty.current = currentShopCartQty;
    }
  }, [shopCartData, isOpen, trackCTCartViewed, CTCartEnabled]);

  useEffect(() => {
    setIsCartLoading(isCartLoading);
  }, [isCartLoading, setIsCartLoading]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const cartId = params.get('cart_id');

    if (cartId && restClient) {
      restClient
        .post(`/ecomm/cart/${cartId}/load`)
        .then(() => {
          refetch();
          openCartPanel();
        })
        .catch(console.error);
    } else if (params.get('cart') === 'open') {
      openCartPanel();
    }
  }, [openCartPanel, restClient]);

  const locale = useLocale();

  if (isContentLoading) {
    return null;
  }
  const { ariaLabel } = content;

  const localeRelativeUrl = addCurrentLocaleToUrl('/checkout', locale);

  const handlePostConversion = () => {
    refetch().then(() => {
      setIsCartLoading(false);
      closeCartPanel();
      window.location.href = localeRelativeUrl;
      // TODO: this should check r3 toggle and go to a different route
    });
  };

  const showCTCartSummary = !hasItemsInMonolithCart && CTCartEnabled;

  return (
    <Flyout
      isOpen={isOpen}
      ariaLabel={ariaLabel?.value}
      backgroundColor={brand.light}
      handleRequestClose={closeCartPanel}
    >
      {showCTCartSummary || isReferralSession ? (
        <NewStyledCartSummary
          handlePostConversion={handlePostConversion}
          isCartEmpty={isCartEmpty}
          loading={loading}
        />
      ) : (
        <StyledCartSummary numberOfItems={data?.cart?.numberOfItems} />
      )}
    </Flyout>
  );
};

export default Panel;

const StyledCartSummary = styled(CartSummary)`
  width: 100vw;
  max-width: 456px;
  overflow: hidden;
`;
const NewStyledCartSummary = styled(NewCartSummary)`
  width: 100vw;
  max-width: 456px;
  overflow: hidden;
`;
