import React, { createContext, useMemo, useState } from 'react';
import type { FC, ReactNode } from 'react';

type NavContextData = {
  selectedNavItem: string | null;
  setSelectedNavItem: React.Dispatch<React.SetStateAction<any>>;
};

export const NavContext = createContext<NavContextData>({
  selectedNavItem: null,
  setSelectedNavItem: (navItem: string) => null,
});

export const NavProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedNavItem, setSelectedNavItem] = useState<string | null>(null);

  const navProviderValue = useMemo(
    () => ({
      selectedNavItem,
      setSelectedNavItem,
    }),
    [selectedNavItem],
  );

  return <NavContext.Provider value={navProviderValue}>{children}</NavContext.Provider>;
};
