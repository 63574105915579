import toFormattedText from '@peloton/copy/toFormattedText';
import { toCountryFromLocale } from '@peloton/internationalize';
import { toLocaleFromTLD } from '@peloton/internationalize/models/locale';
import type { Locale } from '@peloton/internationalize/models/locale';
import useGiftingContent from '@ecomm/cart-next/shared/useGiftingContent';
import { useCommercetoolsClient } from '@ecomm/commercetools/apollo';
import { useSearchProductByKeyQuery } from '@ecomm/commercetools/queries/SearchProductByKey.generated';
import type { SearchProductByKeyQuery } from '@ecomm/commercetools/queries/SearchProductByKey.generated';
import { toCurrencyfromLocale } from '@ecomm/graphql-bridge';
import { toDollars } from '@ecomm/models';
import type { Selection } from '@ecomm/shop/models/Selections';

const EMPTY_ARRAY: any[] = [];
const EMPTY_OBJECT = {};

type Product = SearchProductByKeyQuery['products']['results'][0];
type ProductData = NonNullable<Product['masterData']['current']>;
type VariantData = ProductData['masterVariant'];
type OptionMapper = {
  variant: VariantData;
  content: string;
  locale: Locale;
  product: Product;
};

export type Option = {
  value: string;
  option: string;
  price?: number;
  name: string;
  skus: string[];
  productSlug: string;
  configuration: Selection[];
  months: string | undefined;
};

const toOption = ({ variant, content, locale, product }: OptionMapper): Option => {
  const attributes = variant.attributesRaw.reduce((acc, { name, value }) => {
    acc[name] = value;
    return acc;
  }, {} as Record<string, any>);

  const legacyOptionId = attributes['legacy-option-id'];
  const subscriptionSize = attributes['subscription-size'];
  const sizeLabel = attributes['size-label']?.[locale]?.toLowerCase();
  const name = attributes['variant-name']?.[locale];

  return {
    value: legacyOptionId,
    option: toFormattedText(content, {
      months: subscriptionSize,
      price: toDollars(variant.price?.value.centAmount),
    }).toString(),
    price: variant.price?.value.centAmount,
    name,
    skus: [variant.sku || ''],
    configuration: [
      {
        attribute: sizeLabel,
        option: subscriptionSize,
      },
    ],
    productSlug: product.key || '',
    months: subscriptionSize,
  };
};

const toPrepaidMembershipOptions = (
  data: SearchProductByKeyQuery | undefined,
  content: string,
  locale: Locale,
): Option[] => {
  if (!data?.products) {
    return EMPTY_ARRAY;
  }

  return data.products.results.reduce((acc: Option[], current: Product) => {
    const product = current.masterData.current;
    if (!product) {
      return acc as Option[];
    }
    const masterVariant = product.masterVariant || EMPTY_OBJECT;

    const masterProduct = toOption({
      variant: masterVariant,
      content,
      locale,
      product: current,
    });

    acc.push(masterProduct);

    product.variants.forEach(variantProduct => {
      const result = toOption({
        variant: variantProduct,
        content,
        locale,
        product: current,
      });
      acc.push(result);
    });

    return acc;
  }, []);
};

export const useCTGetPrepaidMembershipOptions = () => {
  const client = useCommercetoolsClient();
  const locale = toLocaleFromTLD();
  const currency = toCurrencyfromLocale(locale);
  const country = toCountryFromLocale(locale);
  const optionLabel = useGiftingContent('addAllAccessMembershipOption');

  const { data, error } = useSearchProductByKeyQuery({
    variables: {
      where: `key="pl-sub-monthly-prepaid"`,
      acceptLanguage: [locale],
      currency,
      country,
    },
    client,
  });

  if (error) {
    console.error('Error fetching SearchProductByKey:', error);
    return EMPTY_ARRAY;
  }

  return toPrepaidMembershipOptions(data, optionLabel, locale);
};
