import { FlexChild, white } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { PelotonText } from '@peloton/logos';
import { media } from '@peloton/styles';
import { Link } from '@ecomm/internationalize-ui';

const MOBILE_WIDTH = 80;
const DESKTOP_WIDTH = 116;

export const HomeLogo: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <FlexChild>
      <StyledLogoLink to="/" href="/">
        <StyledPelotonTextLogo data-test-id="pelotonLogo" fill={white} />
      </StyledLogoLink>
    </FlexChild>
  );
};

const StyledLogoLink = styled(Link)`
  display: inline-flex;
  height: 100%;

  span {
    display: flex;
  }
`;

const StyledPelotonTextLogo = styled(PelotonText)`
  width: ${MOBILE_WIDTH}px;
  height: auto;
  overflow: visible;

  ${media.desktopXLarge`
    width: ${DESKTOP_WIDTH}px;
  `}
`;
