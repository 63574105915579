import { useEffect, useState } from 'react';
import { toCountryFromLocale, toCurrency } from '@peloton/internationalize';
import { useLocale } from '@peloton/internationalize/locales';
import { useCommercetoolsClient } from '@ecomm/commercetools/apollo';
import { SUBSCRIPTION_CATEGORY_KEY } from '@ecomm/commercetools/constants';
import { useGetCategoryByKey } from '@ecomm/commercetools/hooks/useGetCategoryByKey';
import { mapProductToPrepaidSubscription } from '@ecomm/commercetools/mappers/mapProductToPrepaidSubscription';
import type { SearchAllProductsQuery } from '@ecomm/commercetools/queries/SearchAllProducts.generated';
import { useSearchAllProductsLazyQuery } from '@ecomm/commercetools/queries/SearchAllProducts.generated';
import type { ProductProjection } from '@ecomm/commercetools/types.generated';
import { toDollars } from '@ecomm/models';
import type { Option } from '@ecomm/pg-checkout-commercetools/components/cart-panel/GiftingMembership';
import type { VariantCommerceTools } from '@ecomm/pg-shop-accessories-display/models';

export const useGetPrepaidGiftSubscriptionsForCart = (): Option[] => {
  const locale = useLocale();
  const country = toCountryFromLocale(locale);
  const currency = toCurrency(country);
  const client = useCommercetoolsClient();
  const [
    subscriptionProducts,
    setSubscriptionProducts,
  ] = useState<SearchAllProductsQuery>();
  const { data: category } = useGetCategoryByKey(SUBSCRIPTION_CATEGORY_KEY);

  const [searchAllProductsQuery] = useSearchAllProductsLazyQuery({
    client,
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!category?.id) {
      return;
    }

    async function fetchData() {
      const { data: subscriptionProductsReturned } = await searchAllProductsQuery({
        variables: {
          locale,
          country,
          currency,
          filters: [
            {
              model: {
                tree: {
                  path: 'categories.id',
                  rootValues: [],
                  subTreeValues: [category?.id ?? ''],
                },
              },
            },
          ],
        },
      });

      setSubscriptionProducts(subscriptionProductsReturned);
    }

    fetchData();
  }, [category?.id, country, currency, locale, searchAllProductsQuery]);

  if (!subscriptionProducts) {
    return [];
  }

  const prepaidSubscriptionProduct = subscriptionProducts?.productProjectionSearch?.results?.find(
    subscriptionProduct => {
      return subscriptionProduct?.masterVariant?.price?.value?.centAmount !== 0;
    },
  ) as ProductProjection;

  if (!prepaidSubscriptionProduct) {
    return [];
  }

  const prepaidSubscriptionVariants = prepaidSubscriptionProduct?.masterVariant
    ? mapProductToPrepaidSubscription(prepaidSubscriptionProduct, locale)
    : {};

  const ctOptions = prepaidSubscriptionVariants?.variants ?? [];

  const options: Option[] = ctOptions
    .filter(option =>
      option.configurations.find(
        configuration => configuration.attribute === 'subscription-size',
      ),
    )
    .map((option: VariantCommerceTools) => {
      // This is to format the typing so that it is compatible with design system's SingleSelect
      option['value'] = option.legacyOptionId;

      const monthConfiguration =
        option.configurations.find(
          configuration => configuration.attribute === 'subscription-size',
        )?.option ?? '';

      option['option'] = `${monthConfiguration} months for $${toDollars(
        option.price?.amount,
      )}`;

      option['productKey'] = prepaidSubscriptionProduct?.key ?? '';
      return (option as unknown) as Option;
    });
  return options;
};

export default useGetPrepaidGiftSubscriptionsForCart;
