import {
  useHasBeenConverted,
  useSetHasBeenConverted,
} from '@ecomm/cart-next/context/CartContext';
import logAction from '@ecomm/logging/logAction';
import type { GetShopCartJupiterQuery } from '@ecomm/shop-cart/graphql/queries/ShopCart.generated';
import { useMigrationStatus } from '../useMigrationStatus';
import { useConvertCTCart } from './useConvertCTCart';
import { useCTCartData } from './useCTCartData';

/**
 * Hook to handle the conversion of the CT cart to the Monolith cart for referral purposes.
 * @returns An object containing the `handleCartConversion` function.
 */
export const useConvertToMonolithCartForReferral = () => {
  const { refetchCart, shopCartData } = useCTCartData();
  const { convertCTCart } = useConvertCTCart();
  const { CTCartEnabled } = useMigrationStatus();
  const hasBeenConverted = useHasBeenConverted();
  const setHasBeenConverted = useSetHasBeenConverted();

  /**
   * Converts the CT cart to the Monolith cart.
   * @param refetchedCTCart - The refetched CT cart data.
   */
  const convertToMonolithCart = async (refetchedCTCart: GetShopCartJupiterQuery) => {
    logAction('adding a referral', refetchedCTCart);
    await convertCTCart({
      refetchedCTCart,
      context: {
        shopCartData,
        refetchedShopCart: refetchedCTCart,
      },
    });
  };

  /**
   * Handles the cart conversion process.
   */
  const handleCartConversion = async () => {
    if (!CTCartEnabled || hasBeenConverted) {
      return;
    }
    const {
      data: refetchedCTCart,
    }: { data: GetShopCartJupiterQuery | undefined | null } = await refetchCart();

    if (!Boolean(refetchedCTCart)) {
      logAction('refetch returns null data');
      return;
    }
    const shouldConvertToMonolith = Boolean(
      refetchedCTCart?.shopCart?.totalLineItemQuantity && !hasBeenConverted,
    );

    if (shouldConvertToMonolith) {
      // Mark as converted before conversion to prevent duplicate conversions
      setHasBeenConverted(true);
      await convertToMonolithCart(refetchedCTCart as GetShopCartJupiterQuery);
    }
  };

  return {
    handleCartConversion,
  };
};
