import { white, Icon } from '@pelotoncycle/design-system';
import React, { useState } from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import { navHoverOpacity } from '../styles';
import type { GeoPickerProps, ProductSubNavProps } from '../types';
import { HamburgerNav } from './HamburgerNav';

type HamburgerMenuProps = {
  displayHamburgerMenu: boolean;
  productSubNavs: ProductSubNavProps[];
  geoPickerMenu?: GeoPickerProps;
};

export const HamburgerMenu: FC<
  Pick<HamburgerMenuProps, 'productSubNavs' | 'geoPickerMenu'>
> = ({ productSubNavs, geoPickerMenu }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleOpen = () => setIsNavOpen(true);
  const handleClose = () => setIsNavOpen(false);

  return (
    <>
      <StyledHamburgerIcon
        name="mobileMenu"
        height={32}
        primaryColor={white}
        rotate={0}
        onClick={handleOpen}
        style={{ cursor: 'pointer' }}
        aria-label="Open navigation menu"
      />
      {isNavOpen && (
        <HamburgerNav
          productSubNavs={productSubNavs}
          geoPickerMenu={geoPickerMenu}
          onClose={handleClose}
        />
      )}
    </>
  );
};

const StyledHamburgerIcon = styled(Icon)`
  ${navHoverOpacity}
`;
