import {
  black,
  grey,
  white,
  spacing,
  Container,
  Eyebrow,
  Flex,
} from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import { useOauth } from '@peloton/auth/OauthProvider';
import { Link } from '@ecomm/internationalize-ui';
import { NAV_HEIGHT_DESKTOP_LARGE, topNavAnimation } from '../styles';
import type { AccountProps } from '../types';
import { SignInLink } from './SignInLink';
import { SignOutLink } from './SignOutLink';

export const AccountMenu: FC<Pick<AccountProps, 'links' | 'authText'>> = ({
  links,
  authText,
}) => {
  const [currentHref, setCurrentHref] = useState('');
  const { isAuthenticated } = useOauth();

  useEffect(() => {
    setCurrentHref(window.location.href);
  }, []);

  return (
    <StyledAccountMenuContainer>
      <Flex flexDirection="column" minWidth="164px" gap={spacing[24]}>
        {links?.map(link => (
          <Link
            to={link.link.url}
            href={link.link.url}
            key={link.text}
            hasUnderline={false}
          >
            <Eyebrow size="medium">{link.text}</Eyebrow>
          </Link>
        ))}
        <AuthDivider />
        {isAuthenticated ? (
          <SignOutLink text={authText.signOutText} currentHref={currentHref} />
        ) : (
          <SignInLink text={authText.signInText} currentHref={currentHref} />
        )}
      </Flex>
    </StyledAccountMenuContainer>
  );
};

const StyledAccountMenuContainer = styled(Container)`
  position: absolute;
  top: ${NAV_HEIGHT_DESKTOP_LARGE};
  left: 50%;
  width: 244px;
  padding: ${spacing[40]};
  background-color: ${white};
  border-radius: ${spacing[4]};
  box-shadow: 0 30px 70px 0 ${rgba(black, 0.11)};
  ${topNavAnimation}
  transform: translateX(-50%);
`;

const AuthDivider = styled(Flex)`
  border-top: 1px solid ${grey[40]};
`;
