import { useCallback } from 'react';
import { useTracking } from 'react-tracking';
import {
  ACCESSORY,
  isPrepaidMembership,
} from '@ecomm/checkout-commercetools/helpers/ct-cart-helper';
import type { CtCartFragment } from '@ecomm/shop-cart/graphql/fragments.generated';
import { toCTName, toCTCategory, toCTSegmentProducts } from './segmentMappings';

const toEvent = (cart: CtCartFragment) => {
  const hasAccessory = Boolean(
    cart?.lineItems?.some(item => item?.productVariant?.type === ACCESSORY),
  );
  const hasSubscriptionGift = Boolean(
    cart?.lineItems?.some(item => isPrepaidMembership(item)),
  );

  const items = cart?.lineItems ?? [];

  return {
    event: 'Cart Viewed',
    properties: {
      name: toCTName(items),
      cart_id: cart?.id,
      category: toCTCategory(items),
      hasAccessory,
      price: cart?.priceSummaries?.cartPriceSummary?.total?.centAmount ?? 0,
      products: toCTSegmentProducts(items),
      hasGift: cart?.isGift ?? false,
      hasSubscriptionGift,
    },
  };
};

const useCTCartViewedAnalytics = () => {
  const { trackEvent } = useTracking();

  const trackCTCartViewed = useCallback(
    (cartData: CtCartFragment) => {
      trackEvent(toEvent(cartData));
    },
    [trackEvent],
  );

  return {
    trackCTCartViewed,
  };
};

export default useCTCartViewedAnalytics;
