import {
  Display,
  Flex,
  Label,
  spacing,
  white,
  grey,
  Badge,
} from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { startCase } from '@peloton/text';
import type { ProductDetailsCardData } from '@ecomm/copy/helpers/types';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { ProductCardDirection } from './types';

type ProductCardTextProps = {
  direction: ProductCardDirection;
  productCard: any;
};

const filterProductName = (productCard: ProductDetailsCardData) =>
  productCard?.key !== 'nav.productCard.GiftCard'
    ? startCase(productCard?.productName.split(' ')[1] || productCard?.productName)
    : startCase(productCard?.productName);

export const ProductCardText: React.FC<React.PropsWithChildren<ProductCardTextProps>> = ({
  direction,
  productCard,
}) => {
  const isNavBadgingUpdatesEnabled = useIsToggleActive()('intl_promo_end');
  const setPromoBadgeText =
    productCard.promoBadgeText && productCard.promoBadgeText !== 'hide'
      ? productCard.promoBadgeText
      : '';

  const setPromoChangeText =
    productCard.promoChangeText && productCard.promoChangeText !== 'hide'
      ? productCard.promoChangeText
      : '';

  const displayPromoBadgeText = isNavBadgingUpdatesEnabled
    ? setPromoBadgeText
    : setPromoChangeText;
  return (
    <Flex
      flexDirection="column"
      gap={
        direction === ProductCardDirection.Horizontal
          ? spacing[8]
          : { mobile: spacing[4], desktop: spacing[8] }
      }
      minWidth={direction === ProductCardDirection.Horizontal ? '180px' : '178px'}
      maxWidth={
        productCard.promoBadgeText &&
        (direction === ProductCardDirection.Vertical ||
          productCard.key === 'nav.productCard.accessories') &&
        '180px'
      }
    >
      {displayPromoBadgeText && (
        <StyledBadge variant="horizontal" theme="accent">
          {displayPromoBadgeText}
        </StyledBadge>
      )}
      <Flex flexDirection="row" alignItems="center">
        <Display size="small" textColor={white}>
          {filterProductName(productCard)}
        </Display>
      </Flex>
      <Label size="small" textColor={grey[30]}>
        {productCard.description}
      </Label>
    </Flex>
  );
};

const StyledBadge = styled(Badge)`
  font-size: 13px !important;
  border-radius: ${spacing[4]}px;
  padding: ${spacing[4]}px ${spacing[8]}px;
  max-height: ${spacing[24]};
  width: fit-content;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
`;
