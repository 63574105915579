import { white, Container } from '@pelotoncycle/design-system';
import StyledIcon from '@pelotoncycle/design-system/icons/AsyncIcon';
import React from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import { useErrorReporter } from '@peloton/error-reporting/useErrorReporter';
import useCartWithVariables from '@ecomm/cart-next/hooks/useCartWithVariables';
import { useMigrationStatus } from '@ecomm/pg-checkout-commercetools/utils/useMigrationStatus';
import { navHoverOpacity } from '../styles';
import { CartIconBuyFlow } from './CartIconButFlow';
import { CartIconMonolith } from './CartIconMonolith';

export type CartIconProps = {
  displayCart: boolean;
};

export const CartIcon: FC = () => {
  const { errorReporter } = useErrorReporter();
  const { CTCartEnabled } = useMigrationStatus();
  const { data, loading } = useCartWithVariables({
    throwError: false,
    reportSwallowedError: errorReporter.reportError,
  });
  const numberOfItemsInMonolithCart = data?.cart?.numberOfItems ?? 0;
  const isBuyFlow = CTCartEnabled && !numberOfItemsInMonolithCart;

  return (
    <StyledContainer>
      <StyledIcon name="cart" height={32} primaryColor={white} rotate={0} />
      {isBuyFlow ? (
        <CartIconBuyFlow hideCount={loading} />
      ) : (
        <CartIconMonolith hideCount={loading} />
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  position: relative;
  width: auto;
  svg {
    fill: none;
    display: block;
  }
  ${navHoverOpacity}
`;
