import { toVariationCopy } from '@pelotoncycle/page-builder';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import type { ProductNavData, CtaWithUrlData } from '@ecomm/copy/helpers/types';
import type { ProductDetailsCard } from '@ecomm/copy/models.generated';
import { useCtaCopyTestToggle } from '@ecomm/feature-toggle/CtaCopyTest';
import { LinkButton } from '@ecomm/internationalize-ui';

type CtaButtonComponentProps = {
  categoryId: string;
  contentfulKey: ProductDetailsCard;
  cta: CtaWithUrlData;
  handleClose: () => void;
};
export const CtaButtonComponent: React.FC<
  React.PropsWithChildren<CtaButtonComponentProps>
> = ({ categoryId, contentfulKey, cta, handleClose }) => {
  const { trackEvent } = useTracking();

  const { isExperimentActive, variables, activeVariation } = useCtaCopyTestToggle();
  const ctaText = isExperimentActive
    ? toVariationCopy({
        originalCopy: cta.text,
        ctaLink: cta.url,
        activeVariation,
        variables,
      })
    : cta.text;

  return (
    <CtaButton
      key={contentfulKey}
      data-test-id={contentfulKey}
      href={cta.url}
      variant="outline"
      color="light"
      size="small"
      onClick={() => {
        trackEvent({
          properties: {
            category: categoryId,
            unitName: contentfulKey,
            linkName: ctaText,
            linkTo: cta.url,
          },
        });
        handleClose();
      }}
      text={ctaText}
    />
  );
};

type Props = {
  categoryId: string;
  products: ProductNavData['products'];
  handleClose: () => void;
};

const CtaSection: React.FC<React.PropsWithChildren<Props>> = ({
  categoryId,
  products,
  handleClose,
}) => {
  const isAppVariated = products[0].key.match(/^(?=.*nav)(?=.*app)(?=.*_variated)/);
  const { key: contentfulKey, primaryCta } = products[0];

  return (
    <>
      {isAppVariated && (
        <CtaButtonComponent
          categoryId={categoryId}
          cta={primaryCta}
          contentfulKey={contentfulKey}
          handleClose={handleClose}
        />
      )}
      {products.map(({ key, secondaryCta }) =>
        secondaryCta ? (
          <CtaButtonComponent
            categoryId={categoryId}
            cta={secondaryCta}
            contentfulKey={key}
            handleClose={handleClose}
            key={key}
          />
        ) : null,
      )}
    </>
  );
};

export default CtaSection;

const CtaButton = styled(LinkButton)`
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 15rem;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`;
