import { Flex, spacing } from '@pelotoncycle/design-system';
import React, { useState } from 'react';
import type { ProductDetailsCardProps } from '../types';
import { ProductCardContainer } from './ProductCardContainer';
import { ProductCardText } from './ProductCardText';
import { ProductExploreButton } from './ProductExploreButton';
import { ProductImage } from './ProductImage';

type ProductCardProps = {
  productCard: ProductDetailsCardProps;
  direction: 'horizontal' | 'vertical';
  showShopButton?: boolean;
};

export const ProductCard: React.FC<ProductCardProps> = ({
  productCard,
  direction,
  showShopButton = true,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <ProductCardContainer
      direction={direction}
      productCard={productCard}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      isHovered={isHovered}
      showShopButton={showShopButton}
      data-test-id="product-card-container"
    >
      {direction === 'horizontal' ? (
        <>
          <ProductImage image={productCard.image} data-test-id="product-image" />
          <Flex
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            gap={spacing[24]}
          >
            <ProductCardText
              direction={direction}
              productCard={productCard}
              data-test-id="product-card-text"
            />
            <Flex flexDirection="column" width="100%" gap={spacing[16]}>
              <ProductExploreButton
                direction={direction}
                productCard={productCard}
                isHovered={isHovered}
                data-test-id="product-explore-button"
              />
            </Flex>
          </Flex>
        </>
      ) : (
        <>
          <ProductCardText
            direction={direction}
            productCard={productCard}
            data-test-id="product-card-text"
          />
          <Flex flexDirection="column" gap={spacing[16]}>
            <Flex justifyContent="center">
              <ProductImage image={productCard.image} data-test-id="product-image" />
            </Flex>
            <ProductExploreButton
              direction={direction}
              productCard={productCard}
              isHovered={isHovered}
              data-test-id="product-explore-button"
            />
          </Flex>
        </>
      )}
    </ProductCardContainer>
  );
};
