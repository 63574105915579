import { Flex } from '@pelotoncycle/design-system';
import React, { useContext } from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import { useOpenCartPanel } from '@ecomm/cart-next/context/CartContext';
import { NavContext } from '../NavProvider';
import { baseTopNavStyles } from '../styles';
import { CartIcon } from './CartIcon';

export const Cart: FC = () => {
  const { setSelectedNavItem } = useContext(NavContext);
  const openCartPanel = useOpenCartPanel();

  const clickHandler = () => {
    openCartPanel();
    setSelectedNavItem(null);
  };

  return (
    <Flex
      position="relative"
      style={{
        height: '100%',
      }}
    >
      <StyledCartButton onClick={clickHandler}>
        <CartIcon />
      </StyledCartButton>
    </Flex>
  );
};

export default Cart;

const StyledCartButton = styled.button`
  ${baseTopNavStyles}
`;
