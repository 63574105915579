import { Container, Media, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import { toCloudinarySrc } from '@peloton/images/toCloudinarySrc';
import type { ProductDetailsCardData } from '@ecomm/copy/helpers/types';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';

const PRODUCT_IMAGE_DIMENSIONS = {
  mobile: spacing[120],
  desktop: '178px',
};

const PROMO_IMAGE_HEIGHT = '146px';

export const ProductImage: React.FC<
  React.PropsWithChildren<
    Pick<ProductDetailsCardData, 'image' | 'promoBadgeText' | 'promoChangeText'>
  >
> = ({ image: { title, url }, promoBadgeText, promoChangeText }) => {
  const cachedImage = toCloudinarySrc({ src: url, width: 178 });
  const isNavBadgingUpdatesEnabled = useIsToggleActive()('intl_promo_end');
  const setPromoBadgeText =
    promoBadgeText && promoBadgeText !== 'hide' ? promoBadgeText : '';

  const setPromoChangeText =
    promoChangeText && promoChangeText !== 'hide' ? promoChangeText : '';

  const displayPromoBadgeText = isNavBadgingUpdatesEnabled
    ? setPromoBadgeText
    : setPromoChangeText;
  return (
    <Container
      height={displayPromoBadgeText ? PROMO_IMAGE_HEIGHT : PRODUCT_IMAGE_DIMENSIONS}
      width={PRODUCT_IMAGE_DIMENSIONS}
    >
      <Media
        media={{
          alt: title ?? 'Product Card Image',
          mobile: cachedImage ?? '',
          tablet: cachedImage ?? '',
          desktop: cachedImage ?? '',
          type: 'image',
        }}
        width="178px"
      />
    </Container>
  );
};
