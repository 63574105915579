import { white } from '@pelotoncycle/design-system';
import React from 'react';
import { useErrorReporter } from '@peloton/error-reporting';
import useCartWithVariables from '@ecomm/cart-next/hooks/useCartWithVariables';
import { ItemCountBadge } from './ItemCountBadge';

export const CartIconMonolith: React.FC<
  React.PropsWithChildren<{ hideCount: boolean }>
> = ({ hideCount }) => {
  const { errorReporter } = useErrorReporter();
  const numberOfItems =
    useCartWithVariables({
      throwError: false,
      reportSwallowedError: errorReporter.reportError,
      fetchPolicy: 'network-only',
    }).data?.cart?.numberOfItems ?? 0;

  return (
    <>
      {!hideCount && numberOfItems ? (
        <ItemCountBadge size="small" textColor={white}>
          {numberOfItems}
        </ItemCountBadge>
      ) : null}
    </>
  );
};
