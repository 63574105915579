import { Label } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { useLocale } from '@peloton/internationalize';
import type { Money } from '@ecomm/models';
import { toDollars } from '@ecomm/models';
import { useRentalPrices } from '@ecomm/rentals/useRentalPrices';
import { strikethroughTreatment } from '@page-builder/modules/Overview/ShopDrawers/priceUtils';
import useCartWithVariables from '../../hooks/useCartWithVariables';
import CartContent from '../../shared/CartContent';

type Props = {
  priceInCents: Money;
  className?: string;
};

const LeasingBundlePrice: React.FC<React.PropsWithChildren<Props>> = ({
  priceInCents,
  className,
}) => {
  const hasBikePlusLease = useCartWithVariables().data?.cart?.hasBikePlusLease;
  const locale = useLocale();
  const BIKE_RENTAL_PRICE = useRentalPrices('BIKE', locale);
  const BIKE_PLUS_RENTAL_PRICE = useRentalPrices('BIKE_PLUS', locale);
  const monthlyPrice = hasBikePlusLease
    ? BIKE_PLUS_RENTAL_PRICE.monthly
    : BIKE_RENTAL_PRICE.monthly;

  return (
    <StyledLabel size="large" className={className}>
      <CartContent
        field="leasePricing"
        values={{
          setupPrice: toDollars(priceInCents),
          monthlyPrice,
        }}
      />
    </StyledLabel>
  );
};

const StyledLabel = styled(Label)`
  ${strikethroughTreatment}
`;

export default LeasingBundlePrice;
